/* ======= estimate */
.estimate_btn {
  width: 30rem;
  display: flex;
  justify-content: space-around;
}
.estimate-request__info-box {
  width: 100%;
  height: 200px;
  margin: 20px 0px;
  background-color: #fcf8e3;
  padding: 10px;
  padding-left: 30px;
  color: #8c6f3e;
  border: 1px solid #f5e79e;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.estimate-request__user-info {
  width: 100%;
  margin: 10px 0px;
  margin-bottom: 50px;
}
.estimate-request__user-info__title {
  font-weight: 600px;
  font-size: 20px;
}
.estimate-request__user-info__input-box {
  width: 100%;
  height: 200px;
  margin: 10px 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #8c6f3e;
}
.estimate-request__input-container {
  display: flex;
  justify-content: space-around;
}
.estimate-request__input-container-box {
  width: 400px;
  display: flex;
  justify-content: space-around;
}
.estimate-request__checkbox span {
  border: 1px solid black;
  margin-right: 5px;
}

.estimate-request__grid-box {
  height: 250px !important;
  overflow: scroll;
}
.estimate-request__btn-box {
  margin: 20px 0px;
  text-align: center;
}
.estimate-request__btn {
  background-color: white !important;
  border: 1px solid #ccc;
  color: gray;
  font-weight: 400;
  height: 60px !important;
}

.estimate-request__btn:hover {
  background-color: #fff9f9 !important;
  transform: scale(103%);
}

.inventory_upload_btn_undo:hover,
.inventory_upload_btn_redo:hover {
  background-color: #f1948a !important;
}

.inventory_upload_btn_plus:hover,
.inventory_upload_btn_minus:hover {
  background-color: #85c1e9 !important;
}

/* 견적내역 페이지 */
.estimate-list__table_num {
  text-align: center !important;
  width: 80px;
  font-size: 14px !important;
}
.estimate-list__table_es_name {
  text-align: center !important;
  width: 300px;
  font-size: 14px !important;
}
.estimate-list__table_es_cnt {
  text-align: center !important;
  width: 100px;
  font-size: 14px !important;
}
.estimate-list__table_price_cnt {
  text-align: center !important;
  width: 110px;
  font-size: 14px !important;
}
.estimate-list__table_reg_date {
  text-align: center !important;
  width: 110px;
  font-size: 14px !important;
}
.estimate-list__table_alarm_flag {
  text-align: center !important;
  width: 150px;
  font-size: 14px !important;
}
.eastimate-list_nav {
  width: 100%;
  height: 50px;
  background-color: #fff9f9;
}
.eastimate-list_nav-box {
  width: 1000px;
  height: 50px;
  background-color: #fff9f9;
  /* padding-left:3px; */
  padding-top: 12px;
  padding-left: 15px;
  display: flex;
  font-size: 16px;
  font-family: var(--chakra-fontWeights-bold);
}

.estimate-list__container {
  width: 980px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* margin-left:300px!important; */
}
/* === 견적 상세페이지 */

.estimate-detail__body {
  width: 1000px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.estimate-detail__table {
  width: 1000px !important;
}
.estimate-detail__table-box {
  border: 1px solid #ddd;
  height: 40px;
}
.estimate-detail__btn {
  width: 100px !important;
  height: 30px !important;
  font-size: 15px !important;
  font-weight: 600px !important;
  border: 1px solid #ddd;
}
.estimate-detail__btn:hover,
.estimate-detail__btn:visited,
.estimate-detail__btn:focus {
  width: 100px !important;
  height: 30px !important;
  font-size: 15px !important;
  font-weight: 600px !important;
  border: 1px solid #ddd !important;
}
.estimate-detail__btn-red {
  background-color: var(--panda-main-color);
  color: white;
}
.estimate-detail__btn-box {
  margin-right: 750px;
}

.estimate_btn {
  width: 30rem;
  display: flex;
  justify-content: space-between;
}

.estimate-select__base_container {
  display: flex;
  justify-content: center;
}
.estimate-request__table-option-box {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.estimate-request__table-btn-box {
  display: flex;
  justify-content: space-around;
  width: 180px;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .eastimate-list_nav-box {
    width: 100%;
  }
  .estimate-list__container {
    width: 95% !important;
  }

  .estimate-detail__body,
  .estimate-detail__table {
    width: 100% !important;
  }

  .req-password__base {
    width: 100% !important;
  }
}

/* tablet 세로 */
@media (max-width: 820px) {
  .eastimate-list_nav-box p:first-child {
    display: none;
  }

  .estimate-detail__body {
    display: block;
  }
  .estimate-detail__btn-box,
  .estimate-detail__btn-box-replace {
    display: block !important;
  }

  .estimate-detail__body,
  .estimate-detail__table {
    width: 95% !important;
  }

  .estimate-detail__btn-box {
    margin-right: 0;
  }

  .estimate-detail_graph_wrap {
    overflow-x: scroll;
  }

  .estimate-detail_graph_wrap > div {
    width: 200%;
  }
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}
