/* === order view page */

.order-info {
  display: flex;
  justify-content: flex-start;
}
.order-log-img {
  width: 60px;
  height: 20px;
}
 .order-receiver-table {
  height: 240px !important;
}

.order-view-img {
  margin-top: 20px !important;
}

.order-view-img {
  margin-top: 20px !important;
}
.order-purchaser-table {
  border-top:1px solid black;
  width:1000px;
  height: 240px;
}
.order-purchaser-row{
  display:flex;
  height:50px;
}
.order-purchaser-table__name-row{
  height:64px;
  border-bottom: 1px solid var(--chakra-colors-gray-100);
  color: var(--chakra-colors-gray-600);
  font-weight: var(--chakra-fontWeights-bold);
  font-family: var(--chakra-fonts-heading);
}
.order-purchaser-table__email-row{
  height:115px;
  border-bottom: 1px solid var(--chakra-colors-gray-100);
  color: var(--chakra-colors-gray-600);
  font-weight: var(--chakra-fontWeights-bold);
  font-family: var(--chakra-fonts-heading);
}
.order-purchaser-table__phone-row{
  height:59px;
  border-bottom: 1px solid var(--chakra-colors-gray-100);
  color: var(--chakra-colors-gray-600);
  font-weight: var(--chakra-fontWeights-bold);
  font-family: var(--chakra-fonts-heading);
}

.order-view__table-font-size{
  
  font-size:12px!important;
  background-color: #f9f9f9;

}
.order-purchaser-row-name{
  width:90px;
}
.order-purchaser-row-input{
  width:400px;
  
}
.order__filter-checkbox {
  border: none;
  height: 15px;
  margin-top: 3px;
  margin-left: 10px;
}

.order__filter-checkbox span:nth-child(2) {
  border: 2px solid #7f8c8d;
  width: 20px;
  height: 20px;
  
}