
/* 배송지관리 페이지 */
.shipping-search-address{
  display:flex;
}
.shipping-checkbox{
  justify-content: flex-end;
}
.shipping-modal-body{
  display:flex;
  flex-direction: column;
  justify-content:space-around
}


/* 포인트 페이지 */
.point-Header{
  display:flex;
  justify-content:space-between
}
.point-Header_text{
  padding-top:20px;
  font-size:12px;
}
.point-body{
  border-top:1px solid black;
}

.point-table_content, .point-table_point, .point-table_use_point, .point-table_datetime{
  width:200px;
  font-size:12px!important;
  text-align:center!important;
}
.point-table_point{
  width:50px;
}

.point-table_datetime, .point-table_use_point{
  width:60px;
}


/* 쿠폰페이지 */
.coupon-table_title, .coupon-table_discount, .coupon-table_deadline, .coupon-table_minimum{
  width:200px;
  font-size:12px!important;
  text-align:center!important;
  
}
.coupon-table_discount, .coupon-table_deadline{
  width:50px;
}

.coupon-table_minimum{
  width:60px;
}

/* 주문내역 찾기 */
.ordernum-main{
  height:500px;
  width:100%;
}

.ordernum-form{
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  width:400px;
  height:250px;
  
}
.orderNum-input{
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;
  margin-top: var(--chakra-space-2);
  padding: var(--chakra-space-6);
}
.orderNum-btn{
  font-weight: bold;
  width:100%;
  height:40px;
  border:1px solid var(--panda-main-color);
  font-size:15px;
  border-radius: 4px;
  color:var(--panda-main-color);
}
.orderNum-btn:hover{
  color:white;
  background-color: var(--panda-main-color);
}
.check__order-input::placeholder{
  text-align: left;
  font-size:14px;
}