.service-body {
  display: flex;
  flex-direction: column;
  padding-left: 80px;
  margin-bottom: 30px;
  background-color: #fff;
  width: 750px;
  color: #555;
}
.service-title {
  margin-bottom: 50px;
}
.service-box {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}
.service-box-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.service-box-content {
  font-size: 13px;
  margin-bottom: 10px;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .service-body {
    width: 97.5%;
    padding-left: 0;
    margin: 0 auto;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}
