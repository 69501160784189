.view-title{
  font-size:20px;
  font-weight: var(--chakra-fontWeights-semibold);
  margin-bottom:10px;
}

.view-box{
  width:700px;
  border-bottom:1px solid #ccc;
  margin-bottom:10px;
}
.view-box__title{
  width:100%;
  padding:10px 20px;
  font-weight: var(--chakra-fontWeights-semibold);
  border-top:1px solid red;
  border-bottom:1px solid #ccc;
}
.view-box__main, .view-box__main-notice{
  min-height:200px;
  padding-left: 20px;
  padding-top:10px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.view-box__main-notice{
  font-weight: var(--chakra-fontWeights-semibold);
  padding-bottom:20px;
}

.view-box__btn-box, .view-write__btn-box{
  text-align:right;
}
.view-box__btn, .view-write__btn{
  font-size:12px!important;
  width:80px!important;
  height:25px!important;
  font-weight:bold!important;
  color:white;
  font-size:10px;
  background-color:#e38d13!important;
  margin:10px;
}
.view-btn-color{
  background-color:#f7323f!important;
}
.view-box__reply{
  width:100%;
  margin-bottom: 10px;
  padding-right: 20px;
}
.view-box__reply__box{
  width: 100px!important;
  justify-content: flex-start!important;
}
.view-write{
  padding:0px 20px;
}
.view-write__input{
  overflow:visible;
  min-height:150px!important;
}
.view-box__info{
  margin-bottom:10px;
  padding:0px 20px;
}