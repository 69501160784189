.review-talbe{
  width:100%;
  height:680px;
}
.review-talbe_num{
  width:9%;
}
.review-talbe_name{
  width:25%;
}
.review-talbe_register{
  width:15%;
}
.review-talbe_subject{
  width:36%;
}
.review-talbe_date{
  width:15%;
}

