@import "./review";
@import "./qna";
@import "./write";
@import "./view.css";


.board-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:10px;
}
.board-text {
  font-size: 25px;
  color: black;
  font-weight: var(--chakra-fontWeights-semibold);
}
.board-btn {
  width: 120px;
  height: 35px;
  background-color: #f7323f;
  color: white;
  font-size: 15px;
  font-weight: var(--chakra-fontWeights-semibold);
  border: 1px solid #f7323f;
  border-radius: 5px !important;
}