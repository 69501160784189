.brand-main{
  min-width:1000px!important;
  min-height:500px;
}
.brand__img{

  margin-top:5px;
  border:1px solid #eee;
  height:200px;
}
.brand-info-container{
  width:500px;
  margin-left: 20px;
}
.brand-info-container__title{
  display: flex;
  margin-bottom: 10px;
}