.write-background {
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.write-text {
  width: 100%;
  font-size: 30px;
  color: black;
  font-weight: var(--chakra-fontWeights-semibold);
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
}
.write-subject {
  width: 90% !important;
  height: 40px !important;
  margin: 20px 20px;
  border-radius: 3px;
  border: 1px solid black !important;
  color: black;
}
.write-content {
  width: 90% !important;
  height: 200px !important;
  color: black;
  margin: 0px 20px;
  margin-bottom: 20px;
  border: 1px solid black !important;
}
.write-box-checkbox {
  width: 90% !important;
  text-align: right;
}
.write-checkbox {
  color: black;
  /* border:1px solid black; */
}
.write-register-btn {
  width: 82% !important;
  margin: 5px 0px;
  margin-bottom: 10px;
  padding: 4px;
  background-color: #333333 !important;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
}

.write-input_agree_ex {
  max-width: 400px;
  margin: 0 auto;
}

.write-input_agree_ex input {
  margin-right: 10px;
}

.write-input_agree_ex label {
  color: #999;
  max-width: 400px;
}

.write-input_agree_ex label a {
  font-weight: bold;
}
