/* var */
:root {
  --panda-main-color: #ee3842;
  --panda-main-hover-color: #c0392b;
  --panda-main-font-weight: var(--chakra-fontWeights-bold);
}
.cursor {
  cursor: pointer;
}
.clicked {
  font-weight: bold;
}

html {
  overflow: hidden; /* HTML 레벨에서 스크롤 숨김 */
  height: 100%; /* 전체 높이를 화면에 맞춤 */
}

body {
  scrollbar-gutter: stable; /* BODY에서도 스크롤바 공간 유지 */
  overflow-y: scroll; /* 세로 스크롤만 BODY에서 활성화 */
  overflow-x: hidden; /* 가로 스크롤 방지 */
  height: 100%; /* BODY 높이를 화면에 맞춤 */
  margin: 0; /* 여백 제거 */
  padding: 0; /* 여백 제거 */
}

/* 프린터 전용 스타일 */
@media print {
  html,
  body {
    overflow: visible; /* 프린터에서는 스크롤 숨김을 해제 */
    height: auto; /* 전체 높이를 자동 조정 */
  }

  /* 모든 div가 새로운 페이지로 시작하도록 설정 */
  div {
    page-break-inside: avoid; /* 페이지 내부에서 잘리지 않도록 방지 */
    page-break-before: auto; /* 필요 시 새로운 페이지에서 시작 */
    page-break-after: auto; /* 필요 시 페이지 뒤에 새로운 페이지 추가 */
  }

  /* 특정 클래스만 페이지를 나누고 싶을 경우 */
  .page-break {
    page-break-before: always; /* 강제로 새로운 페이지에서 시작 */
  }

  /* 프린터에서 숨길 요소 */
  .no-print {
    display: none; /* 프린터에서 숨김 */
  }
}

/* components */
@import "components/layout";
@import "components/header";

/* pages */
/* mypage */
@import "pages/mypage/info";
@import "pages/mypage/orders";
@import "pages/mypage/index";

/* board */
@import "pages/board/index";
/* board */
@import "pages/parts/category";
/* cart */
@import "pages/cart/view";
/* auth */
@import "pages/policy/service";
/* search */
@import "pages/search/lists";
/* estimate */
@import "pages/estimate/index";
/* auth */
@import "pages/auth/index";
/* order */
@import "pages/order/index";
/* brand */
@import "pages/brand/index";

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  src: url("../public/fonts/Montserrat-Italic-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: url("../public/fonts/OpenSans-VariableFont_wdth\,wght.ttf");
}

@font-face {
  font-family: "Nanum Square";
  font-weight: normal;
  font-style: normal;
  src: url("../public/fonts/NanumSquareRoundR.ttf");
}

@font-face {
  font-family: "NanumBarun";
  font-weight: normal;
  font-style: normal;
  src: url("../public/fonts/NanumBarunGothic.ttf");
}

@font-face {
  font-family: "GmarketSans";
  font-style: normal;
  src: url("../public/fonts/GmarketSansTTFMedium.ttf");
}

/* 쓰는 변수 */
/* font-weight: var(--chakra-fontWeights-semibold); */
html,
body {
  font-family: "Noto Sans kr", Nanum Barun Gothic, Nanum Gothic,
    "Apple SD Gothic Neo", "Nanum Square", "Montserrat", Apple SD Gothic Neo,
    Dotum, Verdana, Arial, GmarketSansTTF, sans-serif !important;
  font-size: 14px;
}
.hidden-table {
  display: none;
}
/*chakra 자체의 아웃라인 생기는 부분 수정*/
*:focus,
*:hover,
*:active {
  outline: 0 !important;
}

a:hover,
a:visited,
a:focus {
  text-decoration: none !important;
  outline-offset: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

button:focus {
  border: none !important;
  outline: none !important;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box !important;
}
.base_body {
  max-width: 1000px;
  margin: 0 auto;
}

.number {
  font-family: Tahoma;
}
.f12 {
  font-size: 12px !important;
}
.f14 {
  font-size: 14px !important;
}
.f15 {
  font-size: 15px !important;
}
.f16 {
  font-size: 16px !important;
}
.f20 {
  font-size: 20px !important;
}
.f22 {
  font-size: 22px !important;
}
.f24 {
  font-size: 24px !important;
}
.f26 {
  font-size: 26px !important;
}
.bold {
  font-weight: bold !important;
}

input,
textarea {
  border-color: #ccc !important;
}
.btn-primary {
  color: #fff !important;
  background-color: #4e73df;
  padding: 2px;
  border-radius: 3px;
}
.btn-primary:hover,
.btn-primary:visited,
.btn-primary:focus {
  color: #fff !important;
  background-color: #4e73df;
  padding: 3px;
  border-radius: 3px;
}
.base_container {
  display: block;
  width: 1000px;
  margin: 0 auto;
}
/* === review page */
.review-star {
  width: 120px !important;
}

/* === qna page */
.checked {
  color: white;
  background-color: #f7323f;
  border: 1px solid #f7323f;
}

.point-text {
  font-weight: 800;
  color: #337ab7;
}
.ppoint-text {
  font-weight: 800;
  color: #f7323f;
}

.notice-list {
  font-size: 12px;
}

/* footer 페이지 */
.footer-notice,
.footer-contact {
  font-size: 13px;
}

.footer-contact__text-box {
  width: 350px;
  display: grid;
  grid-template-columns: 0.6fr 1fr 2fr;
  justify-items: start;
}
/*부품상세*/
.part_view_bc {
  margin-top: 10px !important;
}
.price_table td {
  padding: 10px 3px !important;
}
/*장바구니*/
.cart_num {
  float: left;
}
.cart_num_change {
  float: left;
  padding-left: 10px;
  padding-top: 4px;
}

/*주문페이지*/
.receipt_info_table {
  border: 1px solid #ccc;
}
.receipt_info_table {
  width: 100%;
}
.receipt_info_table th {
  width: 150px !important;
  text-align: left;
  padding-left: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ebebeb;
}

.order_view_cal {
  width: 300px;
  padding-inline-end: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
}

/* ==== estimate detail 페이지에 사용된 스타일*/
.btn_red {
  background: #ee3842;
  color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 10px;
}
.radius-0 {
  border-radius: 0px !important;
}

.btn_white {
  background: #fff;
  color: #707070;
  padding: 5px 10px;
  font-weight: bold;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
.float-right {
  float: right !important;
}
.text-danger {
  color: #a94442;
}
.text-center {
  text-align: center !important;
}
.text-primary {
  color: #4e73df !important;
}
.text-warning {
  color: #f6c23e !important;
}
.text-success {
  color: #1cc88a !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr10 {
  margin-right: 10px !important;
}
.mr40 {
  margin-right: 40px !important;
}

.font-weight-bold {
  font-weight: bold !important;
}
.font10 {
  font-size: 10px !important;
}
.font12 {
  font-size: 12px !important;
}
.font13 {
  font-size: 13px !important;
  margin: 0px;
  padding: 0px;
}
.font18 {
  font-size: 18px !important;
}
/* ==== estimate detail 페이지에 사용된 스타일 종료=====*/

.chakra-radio__control {
  color: #7f8c8d !important;
}
.fa-search-plus {
  margin-left: 5px;
}

.supply-first {
  width: 30px;
  border: none;
}
.supply-second {
  width: 100px;
  height: 29.5px;
}
.supply-third {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* ==== pagination=== */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
ul {
  list-style: none;
  padding: 0;
}
ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}
ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
}
ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
}
ul.pagination li a {
  text-decoration: none;
  color: #337ab7;
  font-size: 1rem;
}
ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #337ab7;
}
ul.pagination li a:hover,
ul.pagination li a.active {
  color: blue;
}
.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.jexcel tbody td:nth-child(2),
.jexcel tbody td:nth-child(3),
.jexcel tbody td:nth-child(4) {
  background-color: #fff7ff !important;
}

/*배송 및 환불 관련*/
.shipping .tab-data table {
  margin-bottom: 40px;
  table-layout: fixed;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 13px;
  width: 100%;
}
.shipping .tab-data th {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  background-color: #fafafa;
  color: #111;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
}
.shipping .tab-data td {
  white-space: normal;
  word-break: break-all;
  padding: 12px 16px;
  color: #333;
  border-bottom: 1px solid #eee;
  border-right: none;
  border-left: none;
  border-top: none;
  line-height: 17px;
  font-size: 12px;
}
.shipping .tab-data h3 {
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: bold;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

/* 데이터시트 */
.datasheet {
  display: flex;
  font-size: 12px;
  color: var(--panda-main-color);
  border: 1px solid var(--panda-main-color);
  padding: 5px 15px;

  margin: 5px;
  width: 120px;
  border-radius: 3px;
}

.datasheet:hover {
  color: white;
  background-color: var(--panda-main-color);
}
.datasheet_icon {
  width: 15px;
  height: 15px;
  margin-right: 5px !important;
}

.page {
  width: 21cm;

  min-height: 29.7cm;

  padding: 2cm;
  padding-top: 0;
  background: #eee;
  margin: 0 auto;
}

.subpage {
  width: 100%;
  height: 100%;
}

.page h3 {
  margin-top: 15px;
  line-height: 2em;
  text-align: left;
  font-size: 13px;
}

.page h3 span {
  display: inline-block;
  font-weight: bold;
}

@page {
  size: A4;

  margin: 0;
}

@media print {
  html,
  body {
    width: 210mm;

    height: 297mm;
  }

  .page {
    margin: 0;

    border: initial;

    width: initial;

    min-height: initial;

    box-shadow: initial;

    background: initial;

    page-break-after: always;
  }
}

.link {
  color: blue;
}

/* top-button */
.scroll_container {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 100;
}
#top {
  font-weight: bold;
  font-size: 20px;
  padding: 7px 15px;
  margin: 0 24px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgb(233, 236, 239);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}
#saleBtn {
  margin-top: 10px;
  padding: 17px 15px 13px 15px;
  background: linear-gradient(
    131.8deg,
    rgb(188, 97, 74) 11.41%,
    rgb(238, 76, 93) 99.63%
  );
  color: #fff;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

#saleBtn i {
  background: url(../public/images/speaker.png) no-repeat 50% 50%;
  background-size: cover;
  filter: invert(96%) sepia(96%) saturate(0%) hue-rotate(324deg)
    brightness(102%) contrast(102%);
  display: inline-block;
  width: 30px;
  height: 30px;
}

#saleBtn:hover {
  background: linear-gradient(
    131.8deg,
    rgb(68, 2, 2) 11.41%,
    rgb(230, 43, 63) 99.63%
  );
}

.register_page {
  /* background: url("../public/images/register_inventory2.jpeg") no-repeat 50% 50%; */
  background-size: cover;
  position: relative;
  height: 1200px;
}

.bg_register {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: #111; */
  top: 0;
  left: 0;
  display: block;
  opacity: 0.35;
  z-index: 1;
}

.sign {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.register_page .write-register-btn:hover {
  background-color: #c74343 !important;
}

.fa-external-link-alt:before {
  content: "\f35d";
}

/* .tui-grid-cell.cell-red {background-color: red}
.tui-grid-cell.cell-green {background-color: green} */
.cell-red {
  background-color: red !important;
}
.cell-green {
  background-color: green;
}

.category_wrap .category_list {
  display: none;
  z-index: 30;
  position: absolute;
  top: 100%;
  left: 0;
  width: 210px;
  height: 610px;
  /*border:1px solid #e1e1e1;*/
  background: #3c464d url("/images/renew_pc/common/catalog_bg.png") no-repeat
    top center / auto auto; /***0429 background-color, height 변경, border삭제***/
  /*-webkit-box-shadow: 10px 7px 16px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 10px 7px 16px 2px rgba(0,0,0,0.2);
    box-shadow: 5px 5px 16px 2px rgba(0,0,0,0.2);*/
} /***box-shadow 삭제***/
.category_wrap.active .category_list {
  display: block;
}
.category_list a {
  display: block;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.category_list a:hover,
.category_list a:focus {
  background-color: #fbeee6;
  color: #3c464d;
} /***0429 추가***/
.category_list .category_b a:hover,
.category_list .category_b a:focus {
  background-color: #333333;
  color: #fff;
}
.category_list > li > a {
  height: 35px;
  line-height: 35px;
  padding: 0 30px 0 13px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #fff;
} /***0429 color 추가***/
.category_list > li.active > a {
  /*background-color:#21418d;*/
  color: #3c464d;
  font-weight: bold;
} /***0429 background-color 삭제, color 변경***/
.category_list > li.active > a {
  background-image: url("/images/renew_pc/common/btn/btn_arr_cate.png");
  background-repeat: no-repeat;
  background-position: 189px 50%;
}
.category_list > li .dp2 {
  display: none;
  position: absolute;
  top: -1px;
  left: 100%;
  min-width: 300px;
  height: 525px;
  padding-top: 57px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 10px 7px 16px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 7px 16px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 16px 2px rgba(0, 0, 0, 0.2);
}
.category_list > li.active .dp2 {
  display: block;
}
.category_list > li .dp2 h2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 22px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 24px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.category_list > li .dp2 .dp2_con {
  padding: 20px 0 0;
  white-space: nowrap;
}
.category_list > li .dp2 .dp2_con:after {
  content: "";
  clear: both;
  display: block;
}
.category_list > li .dp2 .dp2_con > ul {
  display: inline-block;
  vertical-align: top;
}
.category_list > li .dp2 .dp2_con > ul > li {
  position: relative;
  width: 100%;
}
.category_list > li .dp2 .dp2_con > ul > li > a {
  width: 224px;
  height: 26px;
  line-height: 26px;
  padding: 0 20px;
  font-size: 13px;
  letter-spacing: -0.4;
}
.category_list > li .dp2 .dp2_con > ul > li > a:hover,
.category_list > li .dp2 .dp2_con > ul > li > a:focus {
  background-color: #fbeee6;
  color: #3c464d;
} /***0429 추가***/
.category_list .dp2_con > ul > li.active > a {
  background-color: #fbeee6;
  color: #3c464d;
}
.category_list .dp2_con > ul > li .dp3 {
  display: none;
  z-index: 40;
  position: absolute;
  top: 0;
  left: 100%;
  width: 190px;
  margin-left: -22px;
  border: 1px solid #e1e1e1;
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.category_list .dp2_con > ul > li.active .dp3 {
  display: block;
}
.category_list .dp2_con > ul > li .dp3 a {
  height: 23px;
  line-height: 23px;
  padding: 0 10px;
  font-size: 12px;
}
.category_list .dp2_con > ul > li .dp3 a:hover,
.category_list .dp2_con > ul > li .dp3 a:focus {
  background-color: #fbeee6;
  color: #3c464d;
} /***0429 추가***/

.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid transparent;
  background-color: #333;
  border-radius: 50%;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 15px !important;
}

.swiper-pagination-bullet {
  width: 30px !important;
  height: 6px !important;
  border-radius: 0 !important;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.main_distributor_detail .swiper .swiper-button-prev {
  top: 10%;
  left: unset;
  right: 10px;
}

.main_distributor_detail .swiper .swiper-button-next {
  top: 10%;
  left: unset;
  right: 0;
  display: none;
}

.swiper-button-disabled {
  opacity: 0.15 !important;
}

.tab-content-info {
  font-size: 14.5px;
  color: #333;
  line-height: 1.75;
  white-space: pre-wrap;
}

.category_view_list {
  width: 300px;
  height: fit-content;
  position: relative;
  border: 1px solid #e2e8f0;
  padding: 10px;
}

.category_view_list .swiper .swiper-slide {
  position: relative;
  width: 30%;
  height: 75px;
  float: left;
  margin-right: 3.3333%;
}

.category_view_list .swiper .swiper-slide button {
  display: block;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.category_view_list .swiper-button-next {
  right: 0 !important;
  width: 20px !important;
  height: 100% !important;
  background-color: #3c464d !important;
  top: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  z-index: 100;
}

.category_view_list .swiper-button-prev {
  left: 0 !important;
  width: 20px !important;
  height: 100% !important;
  background-color: #3c464d !important;
  top: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  z-index: 100;
}

.category_view_list .swiper-button-next:hover,
.category_view_list .swiper-button-prev:hover {
  background-color: #999;
}

/* 판다파츠 소개 페이지 */
/* 소개 페이지 */
.panda-intro {
  background-color: #fff;
  width: 100%;
  color: #555;
}

.panda-intro_wrap_1st {
  background-color: #fdedec;
  width: 100%;
  height: 500px;
}

.intro_id {
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  background-color: #fdedec;
  height: 100%;
}

.introLeft {
  flex: 1;
  position: relative;
}

.introLeft_ex {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

.introLeft_ex > div {
  background: #fed7d7;
  width: fit-content;
  border-radius: 20px;
  padding: 3px 8px;
  border: 1px solid #ea2f3c;
}

.introLeft_ex > div p {
  color: #ea2f3c;
  font-size: 15px;
}

.introLeft_ex h4 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 15.5px;
}

.introLeft_ex > span {
  display: block;
  font-size: 20px;
  line-height: 1.5;
}

.introRight {
  flex: 1;
}

.introRight img {
  height: 100%;
}

.panda-intro_search {
  background-color: #f6f8fb;
  width: 100%;
  height: 800px;
}

.panda-intro_search_wrap {
  max-width: 1080px;
  position: relative;
  height: 100%;
  margin: 0 auto;
}

.panda-intro_search_tp {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
}

.panda-intro_search_tp > div {
  background: #fed7d7;
  width: fit-content;
  border-radius: 20px;
  padding: 3px 8px;
  margin: 0 auto 13.5px;
  border: 1px solid #ea2f3c;
}

.panda-intro_search_tp > div > p {
  font-size: 15px;
  color: #ea2f3c;
}

.panda-intro_search_tp > h4 {
  display: block;
  font-size: 50px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 18.5px;
}

.panda-intro_search_tp > span {
  display: block;
  font-size: 19px;
  line-height: 1.5;
  text-align: center;
}

.panda-intro_search_btm {
  position: absolute;
  width: 90%;
  height: 400px;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -75%);
}

.panda-intro_search_btm ul {
  width: 100%;
  height: 200px;
}

.panda-intro_search_btm ul li {
  width: calc(90% / 5);
  height: 100%;
  background: #fff;
  margin: 1%;
  position: relative;
  float: left;
}

.panda-intro_search_btm ul li img {
  height: fit-content;
  width: fit-content;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.panda-intro_search_btm ul:first-child li:nth-child(4) img {
  height: fit-content;
  width: 75%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.panda-intro_estimate {
  background-color: #fff;
  width: 100%;
  height: 500px;
}

.panda-intro_estimate_wrap {
  max-width: 1080px;
  position: relative;
  height: 100%;
  margin: 0 auto;
}

.panda-intro_estimate_wrap_ex {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.panda-intro_estimate_wrap_ex h4 {
  display: block;
  font-size: 45px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin-bottom: 30.5px;
}

.panda-intro_estimate_wrap_ex span {
  display: block;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}

.panda-intro_estimate_wrap_ex a {
  display: block;
  background: #ea2f3c;
  font-size: 18px;
  text-align: center;
  color: #fff;
  width: fit-content;
  margin: 30px auto 20px;
  padding: 20px 90px;
  border-radius: 10px;
}

.panda-intro_estimate_wrap_ex a:hover {
  background: #c2000d !important;
  transform: translateY(-3%);
}

.panda-intro_estimate_wrap_ex_req span:first-child {
  color: #444;
  text-align: center;
  display: block;
  font-size: 15px;
}

.panda-intro_estimate_wrap_ex_req span:last-child {
  display: block;
  background: #444;
  color: #fff;
  text-align: center;
  width: fit-content;
  margin: -8px auto 0;
  padding: 5px 8px;
  border-radius: 15px;
}

.menu-bar {
  display: none;
}

/* 고객센터 */
.center-body {
  min-height: 500px;
}

/* faq */
.center-body_list {
  width: 750px;
  background-color: #fff;
  color: #555;
  margin-bottom: 30px;
}

/* qna, 구매후기, 공지사항 */
.center-board_list {
  width: 750px;
  background-color: #fff;
  color: #333;
  font-size: 13px;
}

.btm_gnb {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  border-bottom: 1px solid rgb(225, 225, 225);
  height: 45px;
  background-color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
}

.btm_gnb > div {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
  top: 0;
  left: 50%;
  display: inline-block;
  height: 45px;
  padding-left: 210px;
  margin-left: -550px;
}

.category_mask {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  inset: 0;
  background: "rgba(102, 102, 102, 0.2)";
  display: none;
  z-index: 250;
  position: fixed;
  width: 100%;
  height: 100%;
}

nav.gnb_menu {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  padding-left: 10px;
}

nav.gnb_menu ul {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  padding-left: 10px;
}

nav.gnb_menu ul li {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  padding-left: 10px;
  float: left;
  white-space: nowrap;
  margin-right: 21.5px;
}

nav.gnb_menu ul li a {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
  text-decoration: none;
  max-width: 121px;
  max-height: 42px;
  text-overflow: clip;
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: rgb(51, 51, 51);
  letter-spacing: -0.4px;
}

nav.gnb_menu ul li a p {
  box-sizing: border-box;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  color: "rgb(0, 0, 0)";
  padding: 10px 0;
}

nav.gnb_menu ul li:first-child a p {
  color: rgb(255, 23, 56);
}

nav.gnb_menu ul li:nth-child(2) a p {
  background: url("https://www.icbanq.com/images/renew_pc/artwork_mark.png")
    no-repeat 26px 0;
}

.category_wrap {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 300;
  position: absolute;
  top: 0;
  left: 0;
}

.category_wrap input {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: '"Noto Sans KR", dotum, sans-serif';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: rgb(51, 51, 51);
  border-radius: 0px;
  appearance: none;
}

.category_wrap button.category_btn {
  box-sizing: border-box;
  word-break: break-word;
  text-size-adjust: none;
  margin: 0;
  padding: 0 15px 0 40px;
  border: 0px none;
  margin-top: -5px;
  vertical-align: middle;
  background-color: transparent;
  cursor: pointer;
  border-radius: 0;
  appearance: none;
  outline: none;
  line-height: 42px;
  background: url("../public/images/ico_allMenu.png") no-repeat 12px 50%;
  font-size: 16px;
  font-weight: var(--chakra-fontWeights-bold);
  color: #555;
  text-align: left;
}

.category_wrap.active button.category_btn {
  background-image: url("../public/images/icon_hover.png");
  background-color: #ee3842;
  color: #fff;
  width: 210px;
}

.category_wrap.active .category_list {
  display: block;
}
.category_list a {
  display: block;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.category_list a:hover,
.category_list a:focus {
  background-color: #fbeee6;
  color: #3c464d;
} /***0429 추가***/
.category_list .category_b a:hover,
.category_list .category_b a:focus {
  background-color: #ee3842;
  color: #fff;
}
.category_list > li > a {
  height: 35px;
  line-height: 35px;
  padding: 0 30px 0 13px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #fff;
} /***0429 color 추가***/
.category_list > li.active > a {
  /*background-color:#21418d;*/
  color: #fbeee6;
  font-weight: bold;
} /***0429 background-color 삭제, color 변경***/
.category_list > li.active > a {
  background-image: url("/images/renew_pc/common/btn/btn_arr_cate.png");
  background-repeat: no-repeat;
  background-position: 189px 50%;
}
.category_list > li .dp2 {
  display: none;
  position: absolute;
  top: -43px;
  left: 100%;
  min-width: 300px;
  height: 655px;
  padding-top: 57px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 10px 7px 16px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 7px 16px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 16px 2px rgba(0, 0, 0, 0.2);
}
.category_list > li.active .dp2 {
  display: block;
}
.category_list > li .dp2 h2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 22px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 24px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
}
.category_list > li .dp2 .dp2_con {
  padding: 20px 0 0;
  white-space: nowrap;
}
.category_list > li .dp2 .dp2_con:after {
  content: "";
  clear: both;
  display: block;
}
.category_list > li .dp2 .dp2_con > ul {
  display: inline-block;
  vertical-align: top;
}
.category_list > li .dp2 .dp2_con > ul > li {
  position: relative;
  width: 100%;
}
.category_list > li .dp2 .dp2_con > ul > li > a {
  width: 224px;
  height: 26px;
  line-height: 26px;
  padding: 0 20px;
  font-size: 13px;
  letter-spacing: -0.4;
}
.category_list > li .dp2 .dp2_con > ul > li > a:hover,
.category_list > li .dp2 .dp2_con > ul > li > a:focus {
  background-color: #fbeee6;
  color: #3c464d;
} /***0429 추가***/
.category_list .dp2_con > ul > li.active > a {
  background-color: #fbeee6;
  color: #3c464d;
}
.category_list .dp2_con > ul > li .dp3 {
  display: none;
  z-index: 40;
  position: absolute;
  top: 0;
  left: 100%;
  width: 190px;
  margin-left: -22px;
  border: 2px solid #e1e1e1;
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.category_list .dp2_con > ul > li.active .dp3 {
  display: block;
}
.category_list .dp2_con > ul > li .dp3 a {
  height: 23px;
  line-height: 23px;
  padding: 0 10px;
  font-size: 12px;
}
.category_list .dp2_con > ul > li .dp3 a:hover,
.category_list .dp2_con > ul > li .dp3 a:focus {
  background-color: #fbeee6;
  color: #3c464d;
}

/* 팝업 */

.popUp {
  position: absolute;
  z-index: 500;
  top: 10px;
  left: 10px;
}

.popUp_box {
  width: 450px;
  height: auto;
}

.popUp_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: #444;
  box-shadow: -1px 1px #ccd;
}

.popUp_close a {
  color: #fff;
  font-size: 19.5px;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0 0.15);
}

.popUp_txt {
  position: relative;
  display: flex;
  margin-top: 5px;
}

.popUp_txt input {
  width: 15px;
  height: 15px;
  display: block;
  margin-top: 5px;
}

.popUp_txt a {
  color: #222;
  margin-left: 5px;
  font-size: 15px;
}

.mallview_add_cart_back_order_btn {
  width: 95%;
  margin: 0 auto;
  background-color: #fff !important;
  color: #ee3842 !important;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.mallview_add_cart_back_order_btn:hover {
  background-color: #ccc !important;
}
.search-special {
  width: fit-content;
  color: #fff;
  background: #ee3842;
  padding: 0 5px;
  border-radius: 5px;
}

/* 재고 등록 페이지 시작 */
.inventory_upload_btn {
  width: 100%;
  position: relative;
  height: 100px;
  margin-bottom: 30px;
}

.inventory_upload_btn_lft .category-search {
  margin: 0;
}

.inventory_upload_btn_rt button,
.inventory_upload_btn_rt a {
  background-color: #fc8181;
  border: 1px solid #fc8181;
  color: #fff;
  padding: 5px;
  font-size: 13.5px;
  margin-left: 7.5px;
  border-radius: 5px;
}

.inventory_upload_btn_rt a:hover,
.inventory_upload_btn_rt button:hover {
  background-color: #f56565;
}
/* 재고 등록 페이지 끝 */

.inventory_data_box {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 10px 0;
}

.inventory_data_box_line {
}

.inventory_data_box_line > div {
  height: 100%;
  margin: 0 1.5% 20px;
  position: relative;
}

.inventory_data_box_line > div > div {
  margin-bottom: 10px;
  margin-left: 10px;
  height: 25px;
}

.inventory_data_box_line > div > div:not(:first-child) input {
  margin-bottom: 10px;
  margin-left: 10px;
  width: calc(80% - 90px);
  border-radius: 5px;
  padding: 0 5px;
}

.inventory_data_box_line .inven_data_chk {
  margin-top: 5px;
}

.inventory_data_box_line .inven_data_manufac {
  left: 10px;
}
.inventory_data_box_line .inven_data_price {
  font-size: 14spx;
}

.inventory_data_box_line .inven_data_stock {
  font-size: 14px;
}

.inven_data_name {
}

.inven_data_name span:last-child {
  font-weight: bold;
}

.inventory_data_box_line .inven_data_stock span,
.inventory_data_box_line .inven_data_manufac span,
.inventory_data_box_line .inven_data_price span,
.inventory_data_box_line .inven_data_dc span,
.inventory_data_box_line .inven_data_package span,
.inventory_data_box_line .inven_data_memo span {
  width: 70px;
  display: inline-block;
}

.inventory_data_box_line .inven_data_file input,
.inventory_data_box_line .inven_data_stock input,
.inventory_data_box_line .inven_data_manufac input,
.inventory_data_box_line .inven_data_price input,
.inventory_data_box_line .inven_data_package input,
.inventory_data_box_line .inven_data_memo input,
.inventory_data_box_line .inven_data_dc input {
  border: 1px solid #333;
  padding: 1.5px;
  width: calc(100% - 80px);
}

.inventory_data_box_line .inven_data_file input {
  border: 1px solid #333;
  width: 100%;
}

.market-category-title .category-search {
  height: 35px;
  align-items: center;
}

.market-category-title .category-search > div {
  height: 35px;
}
.market-category-title .category-search h1 {
  width: 114px;
  height: 23px;
  background: url(../public/images//marekt_place_title.png);
  display: block;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: 0 20px 0 40px;
  cursor: pointer;
}

.inventory_upload_btn_lft label {
  margin-right: 10.5px;
}
.inventory_upload_btn_lft label span.chakra-checkbox__control {
  border: 1px solid #ccc !important;
}

.inventory_upload_btn_lft .category-search select.category-search-select-box {
  width: 150px;
  margin-left: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 0 5px;
}

.inventory_upload_btn_lft {
  width: 60%;
  float: left;
  border: 1px solid #ccc;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  height: 100px;
  position: relative;
  padding: 15px;
}

.inventory_upload_btn_lft .inventory_upload_btn_tp,
.inventory_upload_btn_rt .inventory_upload_btn_tp {
  margin-bottom: 10px;
}

.inventory_upload_btn_rt {
  width: 35%;
  float: right;
  height: 100px;
  position: relative;
  padding: 10px 10px;
}

.inventory_upload_btn_rt .inventory_upload_btn_tp {
  width: 120px;
  float: right;
}

.inventory_upload_btn_rt .inventory_upload_btn_btm {
  float: right;
  clear: both;
}

/* 판매자 페이지 메뉴 시작 */
.seller_menu {
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 50px;
  border-bottom: 1px solid #fbeee6;
}

.seller_menu > ul {
  display: flex;
}

.seller_menu > ul > li {
  font-size: 15px;
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #98a3aa !important;
  color: #fff !important;
}

.seller_menu > ul > li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.seller_menu > ul > li:hover a:not(.seller_menu > ul > li.active a) {
  background-color: #3c464d !important;
}

.seller_menu > ul > li.active {
  border-top: 1px solid #fbeee6 !important;
  border-left: 1px solid #fbeee6 !important;
  border-right: 1px solid #fbeee6 !important;
  background-color: #fbeee6 !important;
  color: #3c464d !important;
  font-weight: bold;
}

ul.seller_inventory_menu > li a:hover {
  font-weight: bold;
}

.seller_menu > ul > li:first-child {
  margin-right: 2.5px;
}

.inventory_admin {
  width: 1100px !important;
}

.inventory_upload_excel {
  width: 1100px;
}

.jexcel_container .seller_inventory_menu {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #fbeee6;
  border-top: 1px solid #fbeee6;
  margin-bottom: 20px;
}

.seller_inventory_menu li {
  padding: 0 10px;
}

.seller_inventory_menu li.active {
  font-weight: bold;
}

.seller_inventory_menu li:first-child {
  /* margin-right: 17.5px; */
}

.seller_inventory_menu {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #fbeee6;
  border-top: 1px solid #fbeee6;
  margin-bottom: 20px;
}

.seller_inventory_menu li {
  padding: 0 10px;
}

.seller_inventory_menu li.active {
  font-weight: bold;
}

.seller_inventory_menu li:first-child {
  /* margin-right: 17.5px; */
}

.goto_inventory_page {
  display: block;
  font-size: 13px;
  color: #ee3842;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.goto_inventory_page:hover {
  color: #111;
  background-color: #c0392b;
}

/* 판매자 페이지 메뉴 끝 */

.goto_marketplacestore {
  display: block;
  width: 200px;
  height: 50px;
  font-size: 20px;
  border: 1px solid #202e46;
  padding: 10px 0;
  margin: 20px auto 50px;
  border-radius: 5px;
  background-color: #202e46;
  color: #fff;
}

.goto_marketplacestore:hover {
  border: 1px solid #5499c7;
  background-color: #5499c7;
}

.quotation_modal_box {
  width: 640px;
  padding: 10px 10px 40px;
  border: 3px solid #ccc;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.quotation_modal_box > h2 {
  padding: 5px 10px 15px 30px;
  border-bottom: 1px solid #ddd;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
}

.quotation_modal_box > p {
  float: right;
  font-size: 15px;
  cursor: pointer;
}

.quotation_modal_box .product_detail {
  zoom: 1;
  overflow: hidden;
  margin-top: 20px;
  padding-left: 300px;
}

.quotation_modal_box .product_detail .product_thumb {
  width: 300px;
  left: -300px;
  margin-right: -300px;
  text-align: center;
  float: left;
  position: relative;
  padding: 40px 20px;
}

.quotation_modal_box .product_detail .product_thumb img {
  width: 250px;
}

.quotation_modal_box .product_detail .product_thumb_btn {
  margin-top: 10px;
}

.quotation_modal_box .product_detail .product_txt {
  position: relative;
  float: left;
  width: 100%;
}
.quotation_modal_box .product_detail .product_txt .product_title {
  position: relative;
  height: fit-content;
  padding-bottom: 5px;
}

.quotation_modal_box .product_detail .product_txt .product_title .product_name {
  font-weight: bold;
  font-size: 17.5px;
  color: #111;
  margin-bottom: 5px;
}

.quotation_modal_box .product_detail .product_txt .product_info {
  border-top: 1px solid #ccc;
  margin-top: 7px;
  padding-right: 5px;
}

.quotation_modal_box .product_detail .product_txt .product_info ul {
  margin-top: 5px;
}

.quotation_modal_box .product_detail .product_txt .product_info ul li {
  padding: 3px 0;
  font-size: 13.5px;
  font-weight: 500;
}

.quotation_modal_box .product_detail .product_txt .product_btn {
  margin-top: 7px;
  border-top: 1px solid #ccc;
  padding-right: 5px;
  padding-top: 10px;
}

.quotation_modal_box .product_detail .product_txt .product_btn a {
  display: block;
  cursor: pointer;
  background-color: #ee3842;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  clear: both;
  float: left;
  width: 100%;
  height: 35px;
  padding: 7px 0;
  text-align: center;
}

.quotation_modal_box
  .product_detail
  .product_txt
  .product_btn
  button:first-child {
  float: left;
  width: 100%;
  margin-bottom: 7.5px;
  background-color: orange;
  color: #fff;
  font-weight: 500;
}

.quotation_modal_box .product_detail .product_txt .product_btn a:hover {
  font-weight: bold;
  background-color: #c0392b;
}

.quotation_modal_box
  .product_detail
  .product_txt
  .product_btn
  button:first-child:hover {
  background-color: #f39c12;
  font-weight: bold;
}

/* 서버 점검 팝업 시작 */
.popUp_title {
  width: 100%;
  height: 250px;
  /* background-color: #272953; */
  background-color: #ee3842;
}

.popUp_title > div {
  background-color: #fff;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
}

.popUp_title img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popUp_title h1 {
  width: 100%;
  text-align: center;
  font-size: 42.5px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 180px;
  transform: translateX(-50%);
  font-weight: bold;
  letter-spacing: 0.1em;
}

.popUp_content {
  background-color: #fff;
  height: 270px;
  width: 100%;
  position: relative;
  box-shadow: -1px 1px 1px #eee;
}

.popUp_content > div:first-child {
  font-size: 14.5px;
  text-align: center;
  padding: 25px 30px 15px;
  text-align: left;
}

.popUp_schedule {
  width: 85%;
  margin: 0 auto;
  height: fit-content;
  position: relative;
  border: 1px solid #d6dbdf;
  border-radius: 7.5px;
}

.popUp_content .popUp_schedule > div:first-child {
  padding: 20px 20px 5px;
  font-size: 15px;
  text-align: left;
}

.popUp_content .popUp_schedule > div:nth-child(2) {
  padding: 5px 20px 20px;
  font-size: 15px;
  text-align: left;
}

.popUp_schedule span {
  display: inline-block;
  margin-right: 5px;
  color: #fff;
  font-weight: bold;
  background-color: #fed7d7;
  border-radius: 25px;
  padding: 5px 15px;
}
/* 서버 점검 팝업 끝 */
.quotation_modal_box {
  width: 640px;
  padding: 10px 10px 40px;
  border: 3px solid #ccc;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.quotation_modal_box > h2 {
  padding: 5px 10px 15px 30px;
  border-bottom: 1px solid #ddd;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
}

.quotation_modal_box > p {
  float: right;
  font-size: 15px;
  cursor: pointer;
}

.quotation_modal_box .product_detail {
  zoom: 1;
  overflow: hidden;
  margin-top: 20px;
  padding-left: 300px;
}

.quotation_modal_box .product_detail .product_thumb {
  width: 300px;
  left: -300px;
  margin-right: -300px;
  text-align: center;
  float: left;
  position: relative;
  padding: 40px 20px;
}

.quotation_modal_box .product_detail .product_thumb img {
  width: 250px;
}

.quotation_modal_box .product_detail .product_thumb_btn {
  margin-top: 10px;
}

.quotation_modal_box .product_detail .product_txt {
  position: relative;
  float: left;
  width: 100%;
}
.quotation_modal_box .product_detail .product_txt .product_title {
  position: relative;
  height: fit-content;
  padding-bottom: 5px;
}

.quotation_modal_box .product_detail .product_txt .product_title .product_name {
  font-weight: bold;
  font-size: 17.5px;
  color: #111;
  margin-bottom: 5px;
}

.quotation_modal_box .product_detail .product_txt .product_info {
  border-top: 1px solid #ccc;
  margin-top: 7px;
  padding-right: 5px;
}

.quotation_modal_box .product_detail .product_txt .product_info ul {
  margin-top: 5px;
}

.quotation_modal_box .product_detail .product_txt .product_info ul li {
  padding: 3px 0;
  font-size: 13.5px;
  font-weight: 500;
}

.quotation_modal_box .product_detail .product_txt .product_btn {
  margin-top: 7px;
  border-top: 1px solid #ccc;
  padding-right: 5px;
  padding-top: 10px;
}

.quotation_modal_box .product_detail .product_txt .product_btn a {
  display: block;
  cursor: pointer;
  background-color: #ee3842;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  clear: both;
  float: left;
  width: 100%;
  height: 35px;
  padding: 7px 0;
  text-align: center;
}

.quotation_modal_box
  .product_detail
  .product_txt
  .product_btn
  button:first-child {
  float: left;
  width: 100%;
  margin-bottom: 7.5px;
  background-color: orange;
  color: #fff;
  font-weight: 500;
}

.quotation_modal_box .product_detail .product_txt .product_btn a:hover {
  font-weight: bold;
  background-color: #c0392b;
}

.quotation_modal_box
  .product_detail
  .product_txt
  .product_btn
  button:first-child:hover {
  background-color: #f39c12;
  font-weight: bold;
}

/* 신규 마이페이지 시작 */
.mypage {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 800px;
  margin-bottom: 80px;
}

.mypage_tit h1 {
  font-size: 35px;
  text-align: center;
  margin: 50px auto 75px;
}

.mypage_menu {
  width: 100%;
  max-width: 1100px;
  margin: 50px auto;
}

.mypage_menu ul {
  width: fit-content;

  height: 30px;
}

.mypage_menu ul li {
  float: left;
  color: #363636;
  font-size: 14px;
  text-align: center;
  padding: 0 20px;

  letter-spacing: -0.07em;
  width: fit-content;
}

.mypage_menu ul li button,
.mypage_menu ul li a {
  position: relative;
}

.mypage_menu ul li button.active,
.mypage_menu ul li.active a,
.mypage_menu ul li a.active {
  color: #000;
  font-weight: 600;
  padding-bottom: 0.3125vw;
}
.mypage_content {
  max-width: 1100px;
  margin: 0 auto;
}

.quote_content {
  max-width: unset;
}

.quote_list_detail
  table
  td
  input:not(.mypage_content table td input[type="radio"]) {
  width: unset !important;
  padding: 0 !important;
  border: unset !important;
  line-height: unset !important;
}

.quote_list_detail table th {
  text-align: left !important;
}

.mypage_content h1 {
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 20px;
  max-width: 1100px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  max-width: 1100px;
  margin: 0 auto 50px;
  display: block;
}

.mypage_menu ul li button.active::after,
.mypage_menu ul li a.active::after,
.mypage_menu ul li.active a::after {
  background-color: #000;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.mypage_content {
  width: 100%;
  margin: 50px auto 0;
}

.mypage_info_ex_cont {
  text-align: right;
  font-size: 12px;
  color: #ee3842;
  margin-bottom: 20px;
}

.mypage_content table {
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
}
.mypage_content table th {
  text-align: left;
  border-bottom: 1px solid #dcdcdc;
  background-color: #fbfbfb;
  width: 250px;
  padding: 10px 25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #000;
}

.estimate_quote_gird_body table th {
  padding: 0 0 !important;
  text-align: center !important;
}

.quote_list_detail_body th,
.quote_list_detail_body td {
  font-size: 13.5px !important;
}

.quote_list_detail_body tr:nth-of-type(2) td span {
  font-weight: 600;
  color: #ee3842;
}

.mypage_content
  table
  td:not(.estimate_quote_gird_body table td, .quote_request_table table td) {
  padding: 15px 0 15px 0px;
  border-bottom: 1px solid #dcdcdc;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #000;
}

.mypage_content
  table
  td
  input:not(.mypage_content table td input[type="radio"]) {
  width: 380px;
  padding: 0 10px;
  border: 1px solid #d6d6d6;
  line-height: 30px;
}
.mypage_content table td:last-child label {
  margin-bottom: 5px;
  margin-right: 20px;
}

.mypage_content table tr:first-child {
  border-top: 1px solid #573838;
}

.mypage_info_body td input,
.mypage_info_body td label {
  margin-left: 15px;
}

.mypage_info_register {
  width: 150px;
  height: 60px;
  margin: 60px auto 0;
  border: #ee3842;
  color: #fff;
  background-color: #ee3842;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  display: block;
}

.mypage_content button:hover {
  font-weight: 600;
}

.mypage_order_head tr th {
  padding: 9px 10px 10px 10px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  background-color: #f7f7f7;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #000;
}

.mypage_order_body tr td:first-child {
  text-align: center;
  padding: 15px 10px 14px 10px;
  color: #777;
  /* border-right: 1px dotted #ededed; */
  text-align: center;
}

.mypage_order_body tr td:first-child em,
.mypage_order_body tr td:first-child p,
.mypage_order_body tr td:first-child a {
  display: block;
  text-align: center;
  margin-bottom: 5px;
  color: #333;
}

.mypage_order_body tr:first-child td {
  border-bottom: none !important;
}

.mypage_order_body tr td:first-child a {
  border-bottom: 1px solid #ee3842;
  color: #ee3842;
  width: fit-content;
  margin: 0 auto 5px;
  font-weight: 600;
}
.mypage_order_body_front {
  text-align: left !important;
}

.mypage_order_body_front p,
.mypage_order_body_front a {
  display: inline-block !important;
}

.mypage_order_front {
  display: inline-block !important;
  margin-right: 5px;
}

.mypage_order_body tr td span,
.mypage_order_body tr td a {
  display: block;
  text-align: center;
  margin-bottom: 5px;
}

.mypage_order_body tr td:nth-child(2) span,
.mypage_order_body tr td:nth-child(3) span,
.mypage_order_body tr td:nth-child(4) span,
.mypage_order_body tr td:nth-child(5) span {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
}
.mypage_order_body tr td:nth-child(2) span:last-child,
.mypage_order_body tr td:nth-child(3) span:last-child,
.mypage_order_body tr td:nth-child(4) span:last-child,
.mypage_order_body tr td:nth-child(5) span:last-child {
  border-bottom: none;
}

.mypage_order_items_trace {
  background-color: #ee3842;
  border: 1px solid #ee3842;
  height: 30px;
  width: 70px;
  padding: 5px 1px;
  color: #fff;
  font-weight: 400;
  display: block;
  margin: 0 auto;
}

.mypage_order_items_trace:hover {
  font-weight: 600;
}

.mypage_order_items_trace.disabled {
  background-color: #ccc; /* 비활성화 시의 배경색 */
  border: 1px solid #ccc; /* 비활성화 시의 테두리 색 */
  color: #999; /* 비활성화 시의 글자 색 */
  cursor: not-allowed; /* 비활성화 시 마우스 커서 */
  font-weight: 400; /* 기본 글자 굵기 유지 */
}

.mypage_order_items_trace.disabled:hover {
  font-weight: 400; /* 비활성화 상태에서는 hover 효과 적용 안 함 */
}

.mypage_order_search {
  width: 100%;
  height: 75px;
  border: 2px solid #f0f0f0;
  clear: both;
  position: relative;
  margin-bottom: 50px;
  padding: 20px 20px 20px 30px;
}

.mypage_order_search label {
  padding: 6px 15px 0 0;
  columns: #777;
  font-size: 13px;
  font-weight: 500;
}

.mypage_order_search input {
  width: 380px;
  padding: 0 10px;
  border: 1px solid #d6d6d6;
  line-height: 30px;
}

.mypage_order_search button {
  width: 50px;
  height: 32px;
  font-weight: 400;
  color: #fff;
  margin-left: 10px;
  background-color: #ee3842;
}

.mypage_order_search button:hover {
  font-weight: 600;
}

.mypage_wish_btn {
  margin-top: 15px;
}

.mypage_wish_btn button {
  padding: 3px 10px 4px 10px;
  color: #3e3d3c;
  border: 1px solid #ccc !important;
  text-align: center;
  background-color: #fff !important;
  margin-right: 7.5px;
}

.mypage_coupon_head th,
.mypage_coupon_body td {
  text-align: center !important;
}

.mypage_coupon_body td img {
  margin: 0 auto 10px;
}

.mypage_coupon_menu {
  clear: both;
}

.mypage_coupon_menu ul {
  width: 100%;
  height: 60px;
  margin: 0 auto 50px;
  float: none;
  border-bottom: 1px solid #dbdbdb;
}

.mypage_coupon_menu ul li {
  position: relative;
  width: 200px;
  font-size: 12px;
  text-align: center;
  z-index: 10;
  float: left;
}

.mypage_coupon_menu ul li button {
  margin: 0 0 0 -1px;
  padding: 10px 20px 12px 20px;
  color: #999;
  font-size: 14px;
  border: 1px solid #dadada;
  background-color: #fff;
  width: 200px;
  margin-top: 15px;
}

.mypage_coupon_menu ul li button.active {
  padding-bottom: 16px;
  margin-top: 0;
  color: #333;
  font-weight: 600;
  border: 1px solid #dbdbdb !important;
  border-bottom: none;
  height: 60px;
}

.mypage_coupon_body.body_empty td {
  padding: 50px 0;
}

.mypage_point_view {
  clear: both;
  border: 2px solid #f0f0f0;
  margin: 0 auto 50px;
  width: 65%;
}
.mypage_point_view ul {
  height: 150px;
  width: 100%;
  padding: 20px 10px;
}

.mypage_point_view ul li {
  float: left;
  height: 95%;
  width: 50%;
  text-align: center;
  border-right: 1px solid #f0f0f0;
}

.mypage_point_view ul li:last-child {
  border-right: none;
}

.mypage_point_view ul li p {
  display: block;
  font-size: 15px;
  color: #ee3842;
  font-weight: 600;
}

.mypage_point_view ul li img,
.mypage_point_view ul li p,
.mypage_point_view ul li span {
  text-align: center;
  margin: 0 auto 5px;
}

.mypage_wish_body table tbody td:first-child input {
  width: unset;
}

.mypage_wish_head th,
.mypage_wish_body td {
  text-align: center !important;
}

.mypage_wish_body td img {
  margin: 0 auto;
}

.mypage_address_btn {
  margin-bottom: 20px;
}

.mypage_address_btn:hover {
  font-weight: bold !important;
  cursor: pointer;
}

.mypage_pagination {
  width: 100%;
  position: relative;
  margin: 50px auto;
}

.mypage_home > a {
  display: block;
  float: right;
  margin-top: 5px;
  color: #fff;
  background-color: #111;
  font-weight: 400;
  padding: 5px 10px;
}

.mypage_gotoseller {
  display: block;
  float: right;
  margin-top: 5px;
  color: #fff;
  background-color: #111;
  font-weight: 400;
  padding: 5px 10px;
}

.mypage_home > a:hover,
.mypage_gotoseller a:hover {
  font-weight: 600;
}

.mypage_home_body {
  margin-bottom: 90px;
  clear: both;
}

.mypage_home_body h3 {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}

.mypage_home_body h3 span {
  padding-left: 5px;
  color: #777;
  font-size: 12px;
  font-weight: 500;
}

.mypage_home_order_info {
  margin-bottom: 35px;
  padding: 45px 0;
  border: 1px solid #f0f0f0;
  border-top: 1px solid #dbdbdb;
  text-align: center;
}

.mypage_home_order_info > ul {
  display: inline-block;
  width: 80%;

  vertical-align: middle;
}

.mypage_home_order_info > ul li {
  display: inline-block;
  width: 20%;
  min-width: 66px;
  font-size: 13px;
  text-align: center;
}

.mypage_home_order_info > ul li b {
  display: block;
  padding: 0 65px 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.mypage_home_order_info > ul li strong {
  display: inline-block;
  width: 55px;
  height: 55px;
  padding: 0;
  line-height: 52px;
  color: #ffff;
  font-size: 18px;
  text-align: center;
  vertical-align: middle;
  background-color: #ccc;
  border-radius: 50%;
}

.mypage_home_order_info > ul li i {
  font-size: 22.5px;
  font-weight: 600;
  padding: 10px 0 0 40px;
}

.mypage_home_order_info > ul li:last-child i {
  visibility: hidden;
}

.mypage_home_order_info > ul li b.present {
  font-weight: 600;
}

.mypage_home_order_info > ul li strong.present {
  background-color: #666;
}

.mypage_home_order_cancel {
  display: inline-block;
  width: 19%;
  text-align: center;
  vertical-align: middle;
}

.mypage_home_order_cancel ul {
  display: inline-block;
  width: 70%;
}

.mypage_home_order_cancel ul li {
  padding: 8px 5px 10px 5px;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  border-bottom: 1px dotted #bfbfbf;
}

.mypage_home_order_cancel ul li strong {
  float: right;
}

.mypage_home_body h3 {
  margin-bottom: 10px;
}

.mypage_home_order_recent th {
  text-align: center !important;
}

.mypage_home_order_recent tbody tr:nth-child(odd) td {
  border-bottom: none !important;
}

.mypage_home_order_recent tbody tr td:first-child {
  padding: 15px 30px;
}

.mypage_home_order_recent
  tbody
  tr
  td
  span:not(.mypage_home_order_recent tbody tr td:first-child span) {
  text-align: center;
}

.mypage_home_order_recent tbody tr td span {
  display: block;
  margin: 5px 0;
}

.mypage_home_order_recent tbody tr td span,
.mypage_home_order_recent tbody tr td span strong,
.mypage_home_order_recent tbody tr td span b {
  font-weight: 400 !important;
}

.mypage_home_order_recent tbody tr td span strong {
  margin-right: 5px;
}

.mypage_home_personal {
  border-right: 1px solid #eee;
  padding: 20px 0;
}

.mypage_home_order_recent tbody tr td:nth-child(5) span {
  margin: 10px 0;
}

.mypage_home_recent_od_number {
  border-bottom: 1px solid #ee3842;
  color: #ee3842;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 5px;
  font-weight: 600;
}

.mypage_home_order_tracking {
  background-color: #ee3842;
  border: 1px solid #ee3842;
  padding: 2px 5px;
  color: #fff;
  font-weight: 600;
}

.mypage_home_more_btn {
  float: right;
}

.mypage_homer_quote_recent,
.mypage_homer_point_recent {
  margin-top: 50px;
}

.mypage_homer_quote_recent tbody,
.mypage_homer_point_recent tbody {
  border-bottom: 1px solid #dedede;
}

.mypage_homer_quote_recent tbody td,
.mypage_homer_point_recent tbody td {
  text-align: center;
  height: 50px;
}

.mypage_home_order_empty {
  height: 270px;
  border-bottom: 1px solid #dcdcdc;
}

.mypage_home_order_empty td {
  text-align: center;
}

.mypage_order_detail table {
  margin-top: 75px;
}

.mypage_order_detail table td span {
  margin-left: 20px;
}

.mypage_order_code_btn {
  margin-top: 20px;
  margin-left: 20px;
}

.mypage_order_detail_body td {
  padding: 0 !important;
}

.mypage_order_detail_body tr {
  height: 50px !important;
}

.mypage_order_detail_body td span {
  text-align: center;
  display: block !important;
  height: unset !important;
}

.mypage_order_goback_list {
  width: 175px;
  margin: 60px auto 0;
  border: #ee3842;
  color: #fff;
  background-color: #ee3842;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  display: block;
  text-align: center;
  padding: 20px 0;
}

.mypage_order_goback_list:hover {
  font-weight: 600;
}

.mypage_address_base {
  display: inline-block;
  font-size: 12.5px;
  border: 1px solid #ea2f3c;
  padding: 2.5px 10px;
  color: #ea2f3c;
  margin-right: 10px;
}

/* 신규 마이페이지 끝 */

/* 신규 견적 관려 시작 */
.quote_list_body {
  text-align: center;
}

.quote_list_body td:last-child span {
  color: #999 !important;
}

.quote_list_body td:last-child span.quote_start {
  font-weight: 600;
  color: #ee3842 !important;
}

.quote_list_body td:nth-child(2) a {
  color: #111;
  border-bottom: 1px solid #111;
  padding-bottom: 1.45px;
  font-weight: 600;
}

.quote_list_body td:nth-child(2) a:hover {
  font-weight: 700;
}

.quote_list_detail .center-body {
  min-height: 350px;
  width: 100%;
  max-width: 1100px;
}
.quote_request_body .center-body {
  min-height: 450px;
}

.quote_list_detail .center-body > button {
  width: 50px;
  height: 32px;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
  background-color: #ee3842;
  float: right;
}

.quote_list_detail .center-body > button:hover {
  font-weight: 600;
}
.quote_list_detail_body {
  font-size: 12.5px !important;
}

.quote_list_detail_body th,
.quote_list_detail_body td {
  width: 16.66666666%;
}

.quote_list_detail_body td input {
  margin-left: 10px;
}

.quote_list_detail_body td span {
  margin-left: 20px;
}

.quote_list_detail_body td span.quote_total {
  color: #ee3842;
  font-weight: 600;
  margin-left: 5px;
}

.quote_list_detail_body th i {
  display: inline-block;
  margin-left: 5px;
  color: #ee3842;
}

.quote_request_file_body {
  margin-top: 30px;
}

.quote_list_detail_body .easy-edit-wrapper {
  margin-left: 20px;
}

.quote_list_detail_body .easy-edit-wrapper .font-weight-bold {
  margin-left: 0 !important;
}

.quote_request_file_body .dropzone {
  height: 350px;
  display: block;
  text-align: center;
  padding: 150px 0;
}

.quote_request_body .estimate-request__btn {
  background-color: #111 !important;
  border: none;
  border-radius: 0;
}

.quote_request_file_body .dropzone p {
  font-size: 15px;
}

.quote_request_file_checkList {
  float: right;
  display: flex;
  padding: 15px 0;
  width: 100%;
  justify-content: flex-end;
}

.quote_request_file_ask_btn {
  width: 100%;
  margin-bottom: 50px;
}

.quote_request_file_ask_btn button,
.quote_request_ask_btn {
  width: 150px;
  height: 60px;
  margin: 60px auto 0;
  border: #ee3842;
  color: #fff;
  background-color: #ee3842;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  display: block;
  border-radius: 0;
}

.quote_request_ask_btn {
  width: 150px !important;
  height: 60px !important;
  margin: 60px auto !important;
  border: #ee3842 !important;
  color: #fff !important;
  background-color: #ee3842 !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: block !important;
  border-radius: 0 !important;
}

.quote_request_file_ask_btn button:hover,
.quote_request_ask_btn:hover {
  font-weight: 600 !important;
  border: #ee3842 !important;
  color: #fff !important;
  background-color: #ee3842 !important;
}

.quote_request_file_template {
  margin-bottom: 10px;
}

.quote_request_file_template button {
  border-radius: 0 !important;
  font-weight: 400 !important;
}

.quote_request_file_template button:hover,
.quote_request_file_how:hover {
  font-weight: 600 !important;
}

.quote_request_file_how {
  border-radius: 0 !important;
  font-weight: 400 !important;
  float: right;
  background-color: #111 !important;
  color: #fff !important;
  margin-bottom: 10px !important;
}

.quote_request_file_how_bx {
  position: relative;
  width: 100%;
}

.quote_request_file_how_manual {
  position: absolute;
  border-radius: 0;
  width: fit-content;
  right: -50px;
  top: 10px;
}

.quote_manual_close {
  font-size: 17.5px;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
}

.quote_request_agree {
  width: fit-content;
  margin: 20px auto 0;
}

.quote_request_agree input {
  float: left;
}

.quote_request_agree p {
  float: left;
  margin-top: -3px;
}

.quote_request_body .jexcel td {
  background-color: #fbfbfb !important;
  /* padding: 4px !important; */
}

.quote_request_body .jexcel tbody td:nth-child(2),
.quote_request_body .jexcel tbody td:nth-child(3),
.quote_request_body .jexcel tbody td:nth-child(4) {
  background-color: #fbfbfb !important;
}

.quote_request_body .jexcel tbody td:nth-child(5) {
  background-color: #fff !important;
}

.quote_request_body
  .jexcel
  thead
  tr:first-child
  td:not(.quote_request_body .jexcel tr:first-child td:last-child) {
  color: #ea2f3c;
  font-weight: 600;
  padding: 5px 0;
}

.quote_request_body th span {
  font-size: 6.5px;
  color: #ea2f3c;
  margin-right: 10px;
  display: inline-block;
}

.quote_request_body .mypage_info_body tr:nth-child(4) th span {
  visibility: hidden;
}

.quote_body {
  width: 100%;
  height: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.quote_body > div {
  width: 50%;
  height: 470px;
  position: relative;
  float: left;
  margin: 0 auto;
}

.quote_body > div:last-child {
  height: 100px;
  width: 100%;
}

.quote_body > div:last-child span {
  display: block;
  margin: 20px auto;
  text-align: center;
  font-size: 17.5px;
  color: #ccc;
}

.quote_body_register_img,
.quote_body_register_ex {
  position: relative;
  height: 100%;
}

.quote_body_register_img img,
.quote_body_register_ex > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.quote_body_register_img img {
  margin-right: 5%;
}
.quote_body_register_ex > div {
  margin-left: 5%;
}

.quote_body_register_ex > div > span {
  display: block;
  margin: 20px auto;
  text-align: center;
  font-size: 17.5px;
  color: #ccc;
}

.quote_body_register_ex > div > p {
  display: block;
  width: fit-content;
  text-align: left !important;
  color: #000;
}

.quote_body_register_ex > div > p > span {
  display: block;
}

.quote_body_register_ex > div > p > span:first-child {
  font-weight: 600;
  font-size: 45px;
  display: block;
  margin-bottom: 15px;
  color: #000;
}

.quote_body_register_ex > div > p > span:nth-child(2),
.quote_body_register_ex > div > p > span:nth-child(3) {
  font-size: 35.5px;
  color: #222;
  display: block;
}

.quote_body_register_ex > div > p > span:nth-child(4) {
  margin-top: 25px;
}
.quote_body_register_ex > div > p > span:nth-child(4),
.quote_body_register_ex > div > p > span:nth-child(5) {
  margin-bottom: 5px;
  color: #555;
  font-size: 17.5px;
}

.quote_body img {
  display: block;
  margin: 20px auto 50px;
}

.quote_body > em {
  width: 100%;
  height: 1px;
  border: 1px solid #dcdcdc;
  display: block;
}

.quote_body_btn {
  display: block;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
}

.quote_body_register_img img {
  margin: 0 !important;
}

.quote_center_body {
  height: 100%;
  min-height: 650px;
  width: 100%;
  position: relative;
  /* background-color: rgb(250, 250, 250); */
}

.quote_body_btn a:first-child {
  padding: 10px 20px;
  border: 1px solid #111;
  background-color: #111;
  display: block;
  color: #fff;
  float: left;
  margin-right: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}
.quote_body_btn a:nth-child(2) {
  padding: 10px 20px;
  border: 1px solid #ee3842;
  background-color: #ee3842;
  display: block;
  color: #fff;
  float: left;
  margin-left: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.quote_body_btn a strong {
  font-size: 11.5px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
}

.quote_body_btn a:hover {
  font-weight: 600;
  font-size: 20px;
}

.quote_request_body h1 {
  margin: 0 auto 30px;
}

/* 신규 견적 관려 끝 */

/* 신규 고객센터 시작 */
.service_content {
  width: 100%;
  max-width: 1000px;
  margin: 50px auto 90px;
}

.service_qna_head {
  border-top: 1px solid #dcdcdc;
}

.service_content h1 {
  font-weight: 500;
  font-size: 1.5em;
  float: left;
  margin-bottom: 20px;
}

.service_qna_head tr:first-child {
  border-top: none !important;
}

.service_qna_head tr th {
  text-align: center;
}
.service_qna_body {
  text-align: center;
}

.service_qna_body tr td:first-child {
  text-align: left;
  padding-left: 10px;
}

.service_qna_body tr td:last-child span {
  /* border: #ee3842 !important;
  color: #fff !important;
  background-color: #ee3842 !important; */
  border: #111 !important;
  color: #fff !important;
  background-color: #111 !important;
  font-size: 14px !important;
  border-radius: 0 !important;
  padding: 5px 10px;
}

.service_qna_body tr td:last-child span.clear {
  border: 1px solid #ccc !important;
  color: #3e3d3c !important;
  background-color: #fff !important;
}

.service_faq_body {
  width: 100%;
  clear: both;
  display: block;
  margin-top: 50px;
}

.service_faq_q {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}

.service_content .css-17mg6aq {
  border-top: none !important;
  border-bottom: none !important;
}

.service_faq_body h2 {
  color: #333;
  font-size: 13px;
}

.service_faq_q {
  background-color: #fbfbfb;
}

.service_faq_a > div {
  background-color: #fff !important;
  padding: 30px 10px 30px;
  line-height: 1.5;
}
.service_faq_a > div p {
  display: block;
  margin-bottom: 15px;
}

.service_notice_body td {
  text-align: center;
}

.service_view_body {
  clear: both;
}

.service_view_body h3 {
  overflow: hidden;
  border-top: 2px solid #000000;
  border-bottom: 1px solid #000000;
  background: #fff;
  padding: 16px 0 17px 0;
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  vertical-align: top;
  line-height: 1.4;
}

.service_view_body_info {
  overflow: hidden;
  padding: 20px 0px 20px 0px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  clear: both;
}

.service_view_body_info span:first-child {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.service_view_body_info span:nth-child(2) {
  display: inline-block;
  height: 10px;
  margin: 0 0 0 7px;
  color: #c4c4c4;
  font-size: 15px;
  font-weight: 400;
  vertical-align: top;
}
.service_view_body_info span:nth-child(3) {
  display: inline-block;
  float: right;
  text-align: right;
  color: #c4c4c4;
  font-size: 15px;
  letter-spacing: -0.03em;
}

.service_view_body_cont {
  min-height: 300px;
  padding: 30px 0;
  line-height: 1.8;
  border-bottom: 1px solid #f0f0f0;
}

.service_view_notice_del {
  margin: 10px 0 0 0;
  float: left;
  height: 45px;
  line-height: 43px;
  margin: 20px 0 0 5px;
  width: 110px;
  color: #fff;
  border: 1px solid #f7323f;
  text-align: center;
  font-size: 15px;
  background: #f7323f;
}
.service_view_notice_edit {
  margin: 10px 0 0 0;
  float: left;
  height: 45px;
  line-height: 43px;
  margin: 20px 0 0 5px;
  width: 110px;
  color: #fff;
  border: 1px solid #000000;
  text-align: center;
  font-size: 15px;
  background: #000000;
}

.service_view_body_list {
  margin: 10px 0 0 0;
  float: right;
  height: 45px;
  line-height: 43px;
  margin: 20px 0 0 5px;
  width: 110px;
  color: #fff;
  border: 1px solid #000000;
  text-align: center;
  font-size: 15px;
  background: #000000;
}

.service_view_notice_del:hover,
.service_view_notice_edit:hover,
.service_view_body_list:hover {
  font-weight: 600;
}

.service_qna_write_title {
  border: 1px solid #111;
  width: 100%;
  padding: 5px 10px;
}

.service_qna_write_content {
  border: 1px solid #111;
  width: 100%;
  padding: 5px 10px;
  min-height: 500px;
}

.notice_edit textarea {
  margin-top: 30px;
  padding: 10px;
  width: 100%;
  border: 1px solid #f0f0f0;
}

.notice_edit > div {
  margin-bottom: 20px;
  float: right;
}
.service_view_body_qna_info > div {
  overflow: hidden;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #f0f0f0;
}

.service_view_body_date {
  margin-top: 20px;
}

.service_qna_write_submit {
  width: 150px !important;
  height: 60px !important;
  margin: 60px auto !important;
  border: #ee3842 !important;
  color: #fff !important;
  background-color: #ee3842 !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: block !important;
  border-radius: 0 !important;
}

.service_qna_write_submit:hover,
.service_qna_write_btn:hover {
  font-weight: 600 !important;
}

.service_qna_write_btn {
  border: #ee3842 !important;
  float: right;
  padding: 5px 10px;
  margin-bottom: 20px;
  color: #fff !important;
  background-color: #ee3842 !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: block !important;
  border-radius: 0 !important;
}

.service_review_item_link {
  color: #3182ce;
}
.service_review_item_link:hover {
  font-weight: 600;
}

/* 신규 고객센터 끝 */

/* 마켓플레이스 영문판 시작 */
.category_square_words {
  width: 50% !important;
}

.category_lists_words {
  display: block !important;
  width: 75%;
  margin: 0 auto;
  height: 30px;
  padding: 0;
  font-weight: 500 !important;
}

.category_lists_words:first-child {
  margin-bottom: 5px;
}
.category_lists_words:nth-child(2) {
  margin-top: 5px;
  font-size: 11px !important;
}

.mallCategory-info__box-info:last-child .mallCategory-info-list_item {
  padding: 15px 10px;
}

.category_english_quote_btn {
  text-align: center;
  font-size: 12px !important;
  padding: 5px 0;
}

.mallCategory-banner {
  width: 100%;
  height: 162px;
  position: relative;
}

.mallCategory-banner div {
  /* background: url(../../../images/banner/banner_21.png) no-repeat 50% 50%; */
  background: url(../public/images/en/marketplace_pc.png) no-repeat 50% 50%;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.category-search.header-english {
  border: 1px solid #111;
  width: 100%;
  height: 50px;
  border-radius: 50px;
  padding: 5px 20px;
  color: #333;
}

.category-search.header-english div input {
  display: inline-block;
  height: 40px;
  width: 485px;
  border: none !important;
  padding: 0 !important;
}

.category-search.header-english div button {
  background-color: transparent;
}

.category-search.header-english div button i {
  color: #111;
  font-size: 25px;
  font-weight: 600;
}

.top-bar_transfer_lang {
  position: relative;
}

.top-bar_transfer_lang ul {
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  color: #222;
  padding: 4px 0;
  box-shadow: 1px 1px 3px rgb(39, 55, 70);
}

.top-bar_transfer_lang ul li {
  width: 150px;
  height: 30px;
  padding: 4px 0;
  text-align: center;
  cursor: pointer;
}

.top-bar_transfer_lang ul li span {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
}

.top-bar_transfer_lang ul li span:first-child {
  width: 20px;
  margin-right: 5px;
}

.top-bar_transfer_lang ul li span:nth-child(2) {
  width: 10px;
  margin-right: 5px;
}

.top-bar_transfer_lang ul li span:nth-child(3) {
  width: 52px;
  margin-right: 5px;
}
.top-bar_transfer_lang ul li span:nth-child(4) {
  width: 5px;
}

.top-bar_transfer_lang ul li:last-child span:nth-child(4) {
  visibility: hidden;
}
/* 마겟플레이스 영문판 끝 */

/* 푸터 분리 시작 */
#footer {
  display: block;
}

.mall_proceed {
  text-align: center;
  font-size: 11px !important;
}
.top-bar-en {
  cursor: pointer;
}

.header-english .header-btn > div {
  justify-content: center;
}

.empty_page {
  display: block;
  text-align: center;
  margin: 10px auto 0;
  color: #fff;
  background-color: orange;
  padding: 5px 0;
  width: 125px;
  font-weight: 400;
}

.empty_page:hover {
  font-weight: 500;
}

.estimate_del_btn:hover {
  font-weight: 600 !important;
}

.quote_list_name a:hover {
  font-weight: 600 !important;
}

.cart_tel_content {
  height: 100%;
  width: 100%;
  position: relative;
  margin: 20px auto;
}

.cart_tel_content > div {
  min-height: 100%;
}

.cart_tel_content > div table {
  width: 100%;
  margin: 0 auto 50px;
  max-width: 1100px;
}

.cart_tel_content > div table tr {
  border-top: 1px solid #573838;
}

.cart_tel_content > div table tr th {
  text-align: left;
  border-bottom: 1px solid #dcdcdc;
  background-color: #fbfbfb;
  width: 250px;
  padding: 10px 25px;
  font-weight: 400;
  font-size: 13.5px;
  font-weight: 500;
  line-height: 1.5;
  color: #000;
}

.cart_tel_content > div table tr td {
  padding: 15px 0 15px 0px;
  border-bottom: 1px solid #dcdcdc;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #000;
}

.cart_tel_content > div table tr td input,
.cart-delete-box__tel input {
  width: 380px;
  padding: 0 10px;
  border: 1px solid #d6d6d6;
  line-height: 30px;
}

.cart-delete-box__tel input,
.cart-delete-box__tel p {
  float: left;
}

.cart-delete-box__tel input {
  width: 200px;
}

.cart_tel_content > div table tr th span,
.cart-delete-box__tel span {
  font-size: 6.5px;
  color: #ea2f3c;
  margin-right: 10px;
  display: inline-block;
}

.cart-delete-box__tel span {
}

.cart-delete-box__tel {
  float: right;
}

.cart-delete-box__tel p {
  width: 120px;
  padding: 5px 20px;
  font-weight: 400;
  font-size: 13.5px;
  font-weight: 500;
  line-height: 1.5;
  color: #000;
}

.customer_feedback_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  transition: 0.15s;
}

.customer_feedback_bg {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}

.customer_feedback_box .customer_feedback_content {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.customer_feedback_box .customer_feedback_content h1 {
  text-align: center;
  padding: 20px 0 10px;
  font-size: 25px;
}

.customer_feedback_box .customer_feedback_content h3 {
  text-align: center;
  padding-bottom: 20px;
}

.service_view_body span {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
}

.service_view_body span b {
  font-size: 6.5px;
  color: #ea2f3c;
  margin-right: 10px;
  display: inline-block;
}

.customer_feedback_cont_title {
  margin-top: 30px;
}

.customer_feedback_box .center-body {
  background-color: #fff;
  border-radius: 5px;
}

.customer_feedback_box .center-body .service_view_body {
  padding: 0 20px;
}

.customer_feedback_box .service_view_body_cont {
  min-height: 200px;
  padding: 0 0 30px;
  line-height: 1.8;
  border-bottom: 1px solid #f0f0f0;
}

.customer_feedback_box .service_view_body_cont .service_qna_write_content {
  min-height: 150px;
}

.customer_feedback_btn {
  width: 100%;
  height: 60px;
  position: relative;
}

.customer_feedback_btn > div {
  margin: 0 auto;
  width: 210px;
}

.customer_feedback_chk_agree {
  width: 100%;
  height: 50px;
  margin: 20px 0;
}

.customer_feedback_chk_agree > div {
  float: right;
}

.customer_feedback_submit {
  width: 100px;
  height: 50px;
  float: left;

  cursor: pointer !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: block !important;
  border-radius: 0 !important;
}

.customer_feedback_submit:first-child {
  margin-right: 5px;
  border: #ee3842;
  color: #fff;
  background-color: #ee3842;
}

.customer_feedback_submit:nth-child(2) {
  margin-left: 5px;
  border: #111;
  color: #fff;
  background-color: #111;
}

/* 푸터 분리 끝 */

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  /*  전체 body width 기본값 해제 */
  .top-bar {
    width: 100%;
  }
  .search-gnb {
    width: 100%;
  }

  .maxist_body div {
    width: 100%;
  }

  .maxist_body > div > div p {
    width: 100%;
  }

  .menu_category {
    width: 100%;
  }

  .menu_category_major {
    width: 100%;
  }

  .base_container {
    width: 100%;
  }

  .affiliate_img {
    padding: 2.5rem;
  }

  .center-body,
  .parent {
    display: block !important;
  }

  .center-body {
    width: 100%;
  }

  .parent {
    width: 100% !important;
    background-color: transparent !important;
    height: 150px;
    margin-bottom: 20px;
  }

  .parent h4 {
    float: left;
    width: 30%;
    background-color: #ee3842;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10%;
  }

  .center-nav {
    position: relative;
    float: left;
    top: 0;
    width: 70%;
    height: 100%;
    display: block;
  }

  .center-nav > div {
    display: block !important;
    width: 100%;
    height: 100%;
  }

  .center-nav > div span.cursor {
    display: block;
    width: 45%;
    float: left;
    margin: 1% 1%;
  }

  .center-body_list {
    width: 100%;
  }

  .center-search {
    width: 100%;
  }

  .center-board_list {
    width: 100%;
  }

  .view-box {
    width: 97.5%;
    margin: 0 auto;
  }

  .center-board_list .flex-between {
    width: 100% !important;
  }

  .center-board_list_detail {
    width: 100%;
  }

  .order-body_list {
    width: 100%;
  }

  .children {
    width: 100%;
  }

  /*  footer */
  .maxist_body .footer_menu .footer_menu_list {
    height: 20px;
    margin: 10px 0;
    justify-content: center;
  }

  .maxist_body .footer_menu .footer_menu_list a {
    display: block;
    width: fit-content;
    float: left;
    -webkit-margin-start: none;
    margin-inline-start: none;
    padding: none;
    font-size: 12px;
  }

  .maxist_body > div > div:first-child {
    display: block;
  }

  .maxist_body > div > div:first-child > div {
    padding: 2.5rem 20px;
  }

  .maxist_body > div > div:last-child p {
    text-align: center;
    font-size: 12px;
    padding-top: 13px;
    margin-bottom: 13px;
  }

  /* 테블릿 네비 바 */
  .menu-bar {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 72px;
    z-index: 500;
    background-color: rgba(0, 0, 0);
  }

  .menu-bar ul {
    display: flex;
    flex-direction: row;
  }

  .menu-bar ul li {
    flex: 1;
    text-align: center;
  }

  .menu-bar ul li a,
  .menu-bar ul li button {
    display: block;
    color: #fff;
    font-size: 15px;
    padding: 25px 0;
  }

  .menu-bar ul li a span,
  .menu-bar ul li button span {
    display: inline-block;
    margin-right: 5px;
    font-size: 15px;
  }

  .menu-bar ul li button {
    display: inline-block;
  }

  /* 네비바 카테고리 메뉴 */
  .menu_category {
    min-height: none;
  }

  .menu_category_major {
    width: 100%;
    clear: both;
  }

  .menu_category_middle {
    clear: both;
    border-top: 1px solid #ddd;
    width: 95% !important;
    margin: 0 auto;
  }
  /* 
  .menu_category_minor {
    width: 50%;
    float: left;
  } */

  .menu_category_minor-child {
    width: 100%;
  }

  /* 판다파츠 소개 페이지 */
  .introLeft {
    width: 100%;
    height: 50%;
  }

  .introLeft_ex {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    width: unset;
    padding-top: 7.5%;
  }

  .intro_id {
    display: block;
  }

  .introLeft_ex > div {
    margin: 0 auto;
  }
  .introRight {
    height: 45%;
  }

  .introRight img {
    height: 100%;
    margin: 0 auto;
  }

  .panda-intro_estimate_wrap_ex h4,
  .panda-intro_search_tp > h4,
  .introLeft_ex h4 {
    font-size: 30px;
    text-align: center;
  }

  .panda-intro_estimate_wrap_ex span,
  .panda-intro_search_tp > span,
  .introLeft_ex > span {
    font-size: 14.5px;
    text-align: center;
  }
  .panda-intro_estimate_wrap_ex a {
    font-size: 15px;
    padding: 15px 50px;
  }

  .panda-intro_search_tp {
    width: 100%;
  }

  .panda-intro_search_btm {
    overflow-x: scroll;
  }

  .panda-intro_search_btm ul {
    width: 200%;
  }

  .introLeft_ex > div p {
    font-size: 13.5px;
  }
  .introLeft_ex > div {
    margin-bottom: 10px;
  }

  .panda-intro_search_tp > div > p {
    font-size: 13.5px;
  }

  .center-body,
  .parent {
    display: block !important;
  }

  .affiliate_img {
    padding: 1.5rem;
  }

  div.sign {
    width: 90%;
    padding: 58px 20px 50px;
  }

  .parent h4 {
    padding: 0 9%;
  }

  .mypage-body_table {
    width: 97.5%;
    margin: 0 auto;
  }

  .mypage-body_table_list {
    width: 97.5%;
    margin: 0 auto;
  }

  .order-Header__box,
  .order-detail-header {
    width: 100%;
  }

  .order-deatil__payinfo-box,
  .order-detail-header-textbox {
    width: 100% !important;
  }

  .order-detail-header {
    width: 100% !important;
    height: 150px;
    font-size: 13.5px;
  }

  .order-detail-header-textbox {
    display: block;
  }

  .mypage-body_table_list thead th {
    font-size: 13px;
  }

  .order-deatil__payinfo-contents {
    grid-template-columns: 1fr 2fr;
  }

  /*  고객센터 검색창 */
  .search-input-box input {
    width: 65%;
  }

  .eastimate-list_nav-box {
    padding-left: 7.5%;
  }

  .center-nav > div span {
    height: 15%;
  }

  .modify_info {
    margin: 0 auto;
    width: 500px !important;
  }
}

@media (max-width: 820px) {
  .category_view_list {
    width: 100%;
  }

  .center-body,
  .parent {
    display: block !important;
  }

  .parent h4 {
    padding: 0;
    width: 38%;
    justify-content: center;
  }
  .center-nav {
    width: 62%;
  }
  .center-nav > div {
    padding-top: 15px;
  }

  .center-nav > div span.cursor {
    font-size: 12.5px;
  }

  .category_view_list .swiper .swiper-slide {
    height: 150px;
  }

  /*  고객센터 검색창 */
  .search-input-box input {
    width: 55%;
  }

  .menu-bar ul li a,
  .menu-bar ul li button {
    display: block;
    color: #fff;
    font-size: 15px;
    padding: 17px 0;
    margin: 0 auto;
  }

  .menu-bar ul li {
    flex: 1 1;
    text-align: center;
  }

  .menu-bar ul li a span,
  .menu-bar ul li button span {
    display: block;
    margin-right: 5px;
    font-size: 15px;
  }
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}

/* Jam's PC */
@media (max-height: 800px) {
  .customer_feedback_cont_title {
    margin-top: 20px;
  }

  .customer_feedback_box .service_view_body_cont .service_qna_write_content {
    min-height: 230px;
  }
}
