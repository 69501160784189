.center {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.parent {
  position: relative;
}
.children {
  position: absolute;
  top: -30px;
  left: 0px;
  color: black;
}
.min-height {
  min-height: 500px;
}

.layout-category {
  position: absolute;
  top: 130px;
  width: 200px;
  height: 300px;
  background-color: #f6f6f6;
  color: black;
}
.layout__category-box {
  width: 200px;
  height: 250px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #44465c;
}

.layout-header {
  display: flex;
  justify-content: space-between;
}
.layout-body {
  border-top: 1px solid black;
}

.mypage-children {
  left: 5px;
}
.mypage-Header_text {
  padding-top: 20px;
  font-size: 12px;
}

.mypage-info,
.privacy-children {
  width: 210px;
  left: 0px;
}
