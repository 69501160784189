.noneSearch-img {
  margin-top: 100px;
  width: 100px;
  height: 100px;
}
.noneSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: var(--chakra-fontWeights-semibold);
}
.noneSearch-result {
  font-size: 30px;
  margin: 30px 0px;
}
.search-hidden {
  display: none;
}

.search {
  margin: 30px 0px;
}
.search-main {
  margin-top: 30px;
}

.search__header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.search-filter {
  width: 100%;
  min-height: 150px;
  border-top: 2px solid black;
  border-bottom: 1px solid #eaebec;
  color: black;
  font-size: 11px !important;
  font-weight: normal !important;
}
.search-filter-box,
.search-filter-select {
  width: 1000px;
  min-height: 50px;
  border-bottom: 2px solid #eaebec;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
}
.search-filter-box {
  grid-template-columns: 150px auto 100px;
}
.search-filter-select {
  background-color: white;
  min-height: 60px !important;
  border-bottom: none;
}
.search-filter-box_count {
  height: 100%;
  background-color: white;
  cursor: pointer;
}
.search-filter-box_count-none {
  height: 100%;
}

.search__filter-title {
  height: 100%;
  background-color: #f0f0f0;
  font-weight: bold !important;
  font-size: 13px;
}
.search__filter-listItem-grid {
  width: 145px !important;
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-items: flex-start;
  align-items: center;
}

.search__filter-listItem-grid > p {
  display: inline-block;
  margin-left: 5px;
}
.search__filter-checkbox {
  border: none;
  height: 15px;
  margin-top: 3px;
  margin-left: 20px;
}
.search__filter-checkbox span {
  border: 2px solid #7f8c8d;
  width: 20px;
  height: 20px;
}

.search__filter-listItem {
  height: 50px;
  display: flex;
  margin-left: 10px;
}

.search__filter-list,
.search__filter-select-list {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-items: center;
}
.search__filter-select-list {
  grid-template-columns: repeat(8, 1fr);
}
.search__filter-reset,
.search-filter-box__selected-btn {
  width: 100px !important;
  height: 30px !important;
}
.search__filter-reset:hover {
  background-color: #636e72 !important;
}
.search__filter-listItem_x {
  font-size: 16px;
  font-weight: 600;
  color: gray;
}

.search-filter-box__selected-btn:hover {
  background-color: #c0392b !important;
}
.search__searched-count {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
}
.search-list__header {
  width: 100%;
  height: 50px;
  padding: 10px;
  padding-left: 30px;
  border-top: 1px solid black;
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: #f9f9f9;
}
.search-list__header__box {
  display: flex;
  justify-content: space-around;
}

.search-list__header__checkbox {
  border: 1px solid #f0f0f0;
  margin-right: 5px;
}
.search-list__data {
  width: 1000px !important;
  min-height: 150px;
  margin-bottom: 50px !important;
  display: inline;
  justify-content: space-between;
  background-color: #f0f0f0;
  flex-direction: column;
}
.search-list__img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.search-list__content {
  padding: 20px;
  background-color: #f0f0f0;
  height: 100% !important;
  border-top: 2px solid #7f8c8d;
}

.search-list__content-info,
.search-list_content-dataSheetBox {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
  /* margin-bottom:50px; */
}
.search-list_provider {
  width: 1000px !important;
  border-bottom: 2px solid #eaebec;
  display: flex;
  grid-template-columns: 50px 50px 100px 120px 150px 20px 100px 150px !important;
  justify-items: center;
}
.search-list_provider:last-child {
  margin-bottom: 30px;
}

.search-list_provider__onday-non {
  width: 60px;
  height: 25px;
  font-size: 12px;
  background-color: #3e97ff;
  color: white;
  border-radius: 5px;
}

.search-list_provider__onday {
  width: 33.33%;
  height: 100%;
  position: relative;
}

.search-list_provider__onday span {
  width: 60px;
  height: 25px;
  font-size: 12px;
  background-color: #3e97ff;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-list_provider__onday-non {
  background-color: white;
}

.search-list_provider_export {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.search-list_provider__price {
  display: grid;
  justify-content: space-between;
  grid-template-rows: 1fr 1fr;
}
.search-list_provider__price-infos {
  display: grid;
  grid-template-columns: 80px 80px;
  justify-content: space-between;
}
.search-list_provider_export {
  font-size: 12px;
  align-items: center;
}
.search-list_provider__goto,
.search-list_provider__goto-ask {
  margin: 10px 0px;
  width: 150px;
  height: 40px;
  font-size: 15px;
}

.search-list_provider__goto-ask {
  width: 150px;
  height: 40px;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 10px 0;
  font-weight: 700;
  font-size: 15px;
  background-color: orange;
}
.search-list_provider__goto:hover {
  background-color: #c0392b !important;
}
.search-list_provider__goto-ask:hover {
  background-color: #f39c12 !important;
}

.search-filter__overflow-show {
  height: auto;
  overflow: visible;
}
.search-filter__overflow-hidden {
  height: 50px;
  overflow: hidden;
}
.search-list__contents-provider {
  display: flex;
  font-size: 12px;
}

.search-list_export {
  color: var(--panda-main-color);
}
.none-cursor {
  cursor: none;
}
.search-list__radio input {
  border: 1px solid red;
}

.load_partner-img {
  /* margin-top:30px; */
  width: 200px !important;
  height: 150px !important;
}
.search_loading {
  margin-top: 250px !important;
  padding-top: 7px;
  width: 250px !important;
  height: 170px;
}

.search-list_provider_1st,
.search-list_provider_2nd,
.search-list_provider_3rd {
  display: flex;
}

.search-list_provider_3rd.back_order_btn {
  background-color: #fff !important;
  color: #ee3842 !important;
  border-radius: 3px;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 15px;
  width: 150px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 40px;
  margin: 10px 0;
}

.search-list_provider_3rd.back_order_btn:hover {
  background-color: #ccc !important;
}

.search-list_provider_3rd.back_order_btn.inventory_btn {
  background-color: orange !important;
  color: #fff !important;
  border: none !important;
}

.search-list_provider_3rd.back_order_btn.inventory_btn:hover {
  background-color: #f39c12 !important;
}

.search-filter-close {
  width: fit-content;
  height: fit-content;
  text-align: right;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .search-filter-box,
  .search-filter-select {
    width: 100%;
  }

  .search-list_provider {
    width: 100% !important;
  }

  .search-list_provider__onday-non {
    display: none;
  }

  .search__filter-list,
  .search__filter-select-list {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    height: fit-content !important;
  }

  .search-list_provider_2nd > p {
    width: 90px !important;
  }
}

/* tablet 세로 */
@media (max-width: 820px) {
  .search-list__produc {
    display: block !important;
  }

  .search__filter-listItem-grid {
    width: 130px !important;
  }

  .search__filter-list,
  .search__filter-select-list {
    grid-template-columns: repeat(1, 1fr);
    width: 50%;
  }

  .search-list__content {
    display: block !important;
  }

  .search-list__content .search-list__product {
    width: 100%;
    justify-content: flex-start;
  }

  .search-list__content .search-list__product .search-list__contents-provider,
  .search-list__content .search-list__product a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .search-list__content .search-list__product a > div {
    width: fit-content;
  }

  .search-list_provider {
    flex-wrap: wrap;
    width: 100% !important;
  }
  .search-list_provider_1st {
    width: 100%;
    height: 100px;
    border-top: 1px solid #ccc;
    padding: 10px 0;
    justify-content: center;
  }

  .search-list_provider__onday-non {
    display: none;
  }
  .search-list_provider_2nd {
    flex-wrap: wrap;
    width: 100%;
    height: 50px;
    justify-content: center;
  }
  .search-list_provider_3rd {
    flex-wrap: wrap;
  }

  .search-list_provider__goto,
  .search-list_provider__goto-ask {
    width: 150px;
    font-size: 15px;
  }

  .search-list_provider__goto-ask {
    width: 150px;
    font-size: 15px;
  }

  .search__filter-list,
  .search__filter-select-list {
    grid-template-columns: repeat(2, 1fr);
    width: 100% !important;
  }
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}
