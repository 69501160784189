.header {
  width: 100%;
}
.maxist_body {
  width: 100%;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  width: 1100px;
  color: #555;
  padding-top: 1.25rem;
}
.top-bar,
.search-gnb {
  margin: 0 auto;
}
.search-bar {
  width: 100%;
}
.search-gnb {
  height: 80px;
  width: 1100px;
  color: #fff;
  /* display: grid; */
  /* grid-template-columns: repeat(3, 1fr); */
}

.search-gnb > div:first-child {
}
.search-gnb > div:nth-child(2) {
}

.category-bar {
  display: flex;
  justify-content: space-between;
  border-top: 1px;
  border-bottom: 1px;
  border-color: #ededed;
  width: 100%;
  color: #555;
  font-weight: bold;
  font-size: 16px;
}
.category-bar > div {
  margin: 0 auto;
  width: 1100px;
}
.top-bar a,
.top-bar_box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  -webkit-box-pack: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  /* padding-inline-start: var(--chakra-space-4); */
  /* padding-inline-end: var(--chakra-space-4); */
  color: inherit;
}

.top-boar__register {
  width: 80px !important;
  height: var(--chakra-sizes-10);

  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px !important;
  border: 1px solid var(--panda-main-color);
  color: #ee3842 !important;
}

.top-boar__register:hover {
  background-color: var(--panda-main-color);
  color: white !important;
}

.top-boar__search-input {
  font-weight: var(--chakra-fontWeights-semibold);
}
.logout-btn {
  background-color: white !important;
  display: block !important;
  padding-inline-start: unset !important;
  padding-inline-end: unset !important;
}

/* 서치인풋 */
.top-bar_search-input {
  position: relative;
  border: 1px solid #f7323f;
  width: 100%;
  height: 50px;
  border-radius: 50px;
  padding: 5px 20px;
  color: #333;
}
.top-bar_search-input__search-icon {
  position: absolute;
  top: 15px !important;
  right: 25px;
  font-size: 20px !important;
  color: var(--panda-main-color);
  cursor: pointer;
}
.top-bar_search-input input {
  display: inline-block;
  height: 40px;
  width: 100%;
}
.top-bar_search-input .dropdown-menu {
  width: 410px !important;
  z-index: 999;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #c5c5c5;
  margin-top: 3px;
}
.top-bar_search-input .dropdown-menu a {
  padding: 3px 0px;
}
.top-bar_search-input .dropdown-menu span {
  padding: 3px 0px;
  display: inline-block;
}
.panda-logo {
  margin-bottom: 30px;
  width: 200px !important;
}
.header-link {
  display: flex;
  align-items: center;
  height: 30px !important;
  margin: 10px 0px;
  position: relative;
  margin-right: 25px;
}
.header-link:first-child {
  width: 150px !important;
  height: 100%;
}

.header-link:last-child {
  display: none;
}

.header-logo {
  padding-bottom: 30px;
}

.cursor {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.header-btn > div {
  display: flex;
}

.header-btn > div:first-child {
  margin-bottom: 5px;
}

.header-btn > div img {
  margin-right: 5px;
}

.header-link:not(:nth-child(1)):hover a {
  color: #000;
}

.header_btn_en > div:first-child {
  height: 35px;
  margin-bottom: 5px;
}

.header_heading {
  display: block !important;
  width: 100%;
  height: 20px;
  text-align: center;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .category-bar > div {
    width: 100%;
  }

  .header {
    overflow: hidden;
  }

  .top-bar_box {
    padding: 0 10px;
    margin-left: 10px;
  }
  .search-bar {
    padding-top: 30px;
  }
  .search-gnb {
    display: block !important;
    height: fit-content;
  }
  .search-gnb > div:first-child {
    padding-right: 0;
    width: fit-content;
    margin: 0 auto;
    float: unset !important;
  }
  .search-gnb > div:nth-child(2) {
    width: 98% !important;
    margin: 0 auto;
    float: unset !important;
  }

  .search-gnb > div:nth-child(2) > div {
    width: 100% !important;
  }

  .search-gnb > div:last-child,
  .header-link:first-child,
  .category-bar > div > div:nth-child(2),
  .header-link:nth-child(7),
  .header-link:nth-child(6) {
    display: none;
  }

  .header-link:last-child {
    display: block;
  }

  .header-link {
    flex: 1;
    margin-right: 0;
    display: block;
    text-align: center;
    margin: 0;
    padding: 10px 0;
    height: unset !important;
  }
}
/* tablet 세로 */
@media (max-width: 768px) {
}

/* 모바일  */
@media (max-width: 480px) {
}

/* 모바일(구) */
@media (max-width: 370px) {
}
