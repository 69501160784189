.qna-table {
  border-top: 1px solid black;
}
.qna-title,
.qna-title_list {
  font-size: 12px !important;
  width: auto;
  text-align: center !important;
}
.qna-title_list {
  text-align: left !important;
}
.qna-date {
  font-size: 12px !important;
  width: 110px;
  text-align: center !important;
}
.qna-status {
  font-size: 12px !important;
  width: 100px;
  text-align: center !important;
}
.qna-status-box {
  width: 80px;
  height: 30px;

  color: gray;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  font-weight: var(--chakra-fontWeights-semibold);
}
.search-input-box {
  position: relative;
  color: black;
}
.search-input__search-icon {
  position: absolute;
  right: 200px;
  font-size: 20px !important;
  color: var(--panda-main-color);
  cursor: pointer;
  z-index: 8;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
}

/* tablet 세로 */
@media (max-width: 768px) {
}

/* 모바일  */
@media (max-width: 480px) {
  .search-input-box input {
    width: 95%;
  }
  .search-input__search-icon {
    right: 5%;
  }

  .center-board_list table thead th {
    font-size: 11px;
    padding: 0 2.5%;
  }

  .center-board_list table thead {
    height: 50px;
  }

  .center-board_list table tbody td {
    padding: 0;
    text-align: center;
    height: 50px;
    font-size: 10px;
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
}
